import React, { useCallback } from 'react';
import { Header as HeaderComponent, Button, CogOutline, FlagOutline, UserGroupOutline } from 'ui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch, useLocation, Link as RouterLink } from 'react-router-dom';
import CXLogo from 'assets/corenetx-logo.png';
import { useFeatureFlags } from 'feature-flags/useFeatureFlags';

const { MenuContainer, Link } = HeaderComponent;

interface IPreLoginHeader {
  showLoginButton?: boolean;
}

export const PreLoginHeader: React.FC<IPreLoginHeader> = ({ showLoginButton = true, ...props }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const redirect = useCallback((to: string) => {
    navigate(to);
  }, []);
  const isSelected = useCallback((params: { path: string; exact: boolean }) => Boolean(useMatch(params)), []);
  const { showFormBuilder, enableMockAccountDashboard, enableToggleFeatureFlagPanel } = useFeatureFlags();

  const showMenu = showFormBuilder || showLoginButton;

  const navigateToInfoPage = () => {
    window.open('https://info.corenet.gov.sg/');
  };

  return (
    <HeaderComponent
      {...props}
      path={pathname}
      redirect={redirect}
      isSelected={isSelected}
      logo={
        <RouterLink tabIndex={-1} to="/" data-cy="master-header-logo">
          <div className="flex">
            <button className="focus:outline-none focus-within:ring-2 ring-primary-60 ring-offset-4">
              <img alt="Corenet X logo" src={CXLogo} className="w-[125px]" />
            </button>
          </div>
        </RouterLink>
      }
    >
      {showMenu ? (
        <MenuContainer>
          {enableMockAccountDashboard && (
            <Link path="/mock-accounts">
              <div className="flex gap-x-2 items-center">
                <UserGroupOutline className="flex-shrink-0 w-6 h-6" />
                <span>Mock Accounts</span>
              </div>
            </Link>
          )}

          {showFormBuilder && (
            <Link path="/form-builder">
              <div className="flex gap-x-2 items-center">
                <CogOutline className="flex-shrink-0 w-6 h-6" />
                <span>Form Builder</span>
              </div>
            </Link>
          )}

          {enableToggleFeatureFlagPanel && (
            <Link path="/toggle-flags">
              <div className="flex gap-x-2 items-center">
                <FlagOutline className="flex-shrink-0 w-6 h-6" />
                <span>Feature Flags</span>
              </div>
            </Link>
          )}

          {true && ( // eslint-disable-line no-constant-binary-expression -- was enableInfoLink
            <div className="w-max">
              <div className="hidden sm:block">
                <Button
                  data-cy="pre-login-header_login-button"
                  label={t('landingPage:r1.mainSection.infoButton')}
                  variant="secondary"
                  onClick={navigateToInfoPage}
                  data-sentry-ignore-slow-click
                />
              </div>
              <div className="sm:hidden">
                <Link external={true} href="https://info.corenet.gov.sg/" data-sentry-ignore-slow-click>
                  {t('landingPage:r1.mainSection.infoButton')}
                </Link>
              </div>
            </div>
          )}

          {showLoginButton && (
            <div className="w-max">
              <div className="hidden sm:block">
                <Button
                  data-cy="pre-login-header_login-button"
                  label={t('landingPage:r1.mainSection.loginButton')}
                  onClick={() => navigate('/login-with-singpass')}
                />
              </div>
              <div className="sm:hidden">
                <Link path="/login-with-singpass">{t('landingPage:r1.mainSection.loginButton')}</Link>
              </div>
            </div>
          )}
        </MenuContainer>
      ) : null}
    </HeaderComponent>
  );
};
