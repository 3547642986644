/* istanbul ignore file */

import { axiosInstance } from './axiosInstance';
import { Dispatch } from 'redux';
import { IAccount, IAssistant, IProject } from 'utils/constants';
import { setAssistants, setAssistant, setAssistantFor } from 'store/assistants/actions';
import { RawProject } from './projects';
import { NO_OP } from '../utils';

interface IAssistanceInfoResponse {
  assistantFor: IAccount[];
  assistantForProjects: {
    project: RawProject;
    assisting: IAccount[];
  }[];
}

interface IAssistantsResponse {
  assistants: IAssistant[];
}

interface IAssistantResponse {
  assistant: IAssistant;
  projects?: IProject[];
}

export interface ICreateAssistantRequest {
  identity: string;
  canEditAll: boolean;
  name: string;
  emailAddress?: string;
  secondaryEmailAddress?: string;
  projects?: string[];
}

export interface IUpdateAssistantRequest {
  canEditAll: boolean;
  projects?: string[];
}

export const fetchAssistanceInfo = (): Promise<IAssistanceInfoResponse> =>
  axiosInstance
    .get<IAssistanceInfoResponse>('/assistant/info')
    .then(({ data: { assistantFor, assistantForProjects } }) => {
      return { assistantFor, assistantForProjects };
    });

export const fetchAssistantsFor = (dispatch: Dispatch): Promise<void> =>
  axiosInstance
    .get<IAssistanceInfoResponse>('/assistant/info')
    .then(({ data: { assistantFor, assistantForProjects } }) => {
      dispatch(
        setAssistantFor(
          assistantFor,
          assistantForProjects.map((a) => ({ ...a, projectId: a.project.uuid })),
        ),
      );
    })
    .catch(() => {
      dispatch(setAssistantFor(null));
    });

export const fetchAssistants = (dispatch: Dispatch): Promise<void> =>
  axiosInstance
    .get<IAssistantsResponse>('/assistant')
    .then(({ data: { assistants } }) => {
      dispatch(setAssistants(assistants));
    })
    .catch(() => {
      dispatch(setAssistants(null));
    });

export const fetchAssistant = (dispatch: Dispatch, assistantId: string): Promise<void> =>
  axiosInstance
    .get<IAssistantResponse>(`/assistant/${assistantId}`)
    .then(({ data: { assistant, projects } }) => {
      dispatch(
        setAssistant(
          assistant,
          projects?.map((p) => p.uuid),
        ),
      );
    })
    .catch(NO_OP);

export const createAssistant = (dispatch: Dispatch, data: ICreateAssistantRequest): Promise<void> =>
  axiosInstance.post<IAssistantResponse>('/assistant', data).then(({ data: { assistant, projects } }) => {
    dispatch(
      setAssistant(
        assistant,
        projects?.map((p) => p.uuid),
      ),
    );
  });

export const getAssistantsForMembers = (data: { projectId: string; memberAccountIds: string[] }): Promise<IAccount[]> =>
  axiosInstance
    .post<{ assistants: IAccount[] }>('/assistant/get-members-assistants', data)
    .then(({ data: { assistants } }) => assistants);

export const updateAssistant = (
  dispatch: Dispatch,
  assistantId: string,
  data: IUpdateAssistantRequest,
): Promise<void> =>
  axiosInstance
    .patch<IAssistantResponse>(`/assistant/${assistantId}`, data)
    .then(({ data: { assistant, projects } }) => {
      dispatch(
        setAssistant(
          assistant,
          projects?.map((p) => p.uuid),
        ),
      );
    });
