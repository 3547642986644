import React from 'react';
import classNames from 'classnames';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { IDescription } from './interfaces';

const colorStyles = {
  informational: 'text-informational',
  error: 'text-error',
  success: 'text-success',
  neutral: 'text-gray-60',
};

export interface ITabButtonVertical {
  label: string;
  description?: IDescription;
  isChecked?: boolean;
  isActive?: boolean;
  disabled?: boolean;
  onClick: () => void;
  icon?: React.ReactNode;
  withinSubsection?: boolean;
  isSubsectionActive?: boolean;
}

export const TabButtonVertical: React.FC<ITabButtonVertical> = ({
  label,
  description,
  isChecked,
  isActive,
  disabled,
  onClick,
  icon,
  withinSubsection,
  isSubsectionActive,
}) => {
  const descriptionColor = colorStyles[description?.color ?? 'neutral'];

  return (
    <button
      className={classNames(
        {
          'text-primary-60 active:text-primary-50 bg-gray-10 border-l-primary-60 active:border-l-primary-50': isActive,
        },
        {
          'border-l-transparent border-y border-b-gray-10 border-t-transparent enabled:hover:border-y-0 enabled:hover:border-transparent enabled:hover:border-l-4 enabled:hover:border-l-gray-30 enabled:hover:pl-4 enabled:hover:bg-gray-10':
            !isActive,
        },
        { 'sub-heading': !withinSubsection },
        { 'label-large': withinSubsection },
        { 'text-primary-60': isSubsectionActive },
        { 'text-gray-80 active:text-gray-70': !isActive && !isSubsectionActive },
        'active:bg-gray-10/60 box-border min-h-[60px] text-left pl-4 pr-5 py-5 border-l-4 focus:outline-none focus-visible:pl-[18px] focus-visible:border-2 focus-visible:border-primary-60 disabled:cursor-not-allowed disabled:text-gray-30',
      )}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <div className="flex justify-between">
        <div className="flex space-x-2">
          {isChecked !== undefined && (
            <CheckCircleIcon
              className={classNames(
                { 'text-success': isChecked },
                { 'text-gray-30': !isChecked },
                'flex-shrink-0 w-5 h-5',
              )}
            />
          )}
          <div className="flex flex-col space-y-1">
            <span className={classNames({ 'pl-7': withinSubsection && isChecked === undefined })}>{label}</span>
            {description && (
              <span
                className={classNames(
                  { 'pl-7': withinSubsection && isChecked === undefined },
                  'captions',
                  descriptionColor,
                )}
              >
                {description.text}
              </span>
            )}
          </div>
        </div>
        {icon}
      </div>
    </button>
  );
};
