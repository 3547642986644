import { ITEM, LIST } from '../keys';

export const all = ['submission'] as const;
export const lists = [...all, LIST] as const;
export const list = <T extends Record<string, unknown>>(opts: T) => [...lists, opts] as const;
export const items = [...all, ITEM] as const;
export const item = (id: string) => [...items, id] as const;
export const aopoStatus = (id: string) => [...items, `submission-${id}`] as const;
export const licenses = (id: string) => [...items, id, 'licenses'] as const;
export const events = (id: string) => [...items, id, 'events'] as const;
