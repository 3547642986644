import { useEffect, createContext, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { NO_OP } from 'utils';

export const useScrollToTop = ({ enabled = true }: { enabled?: boolean } = {}): void => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!enabled) return;

    window.scrollTo({
      top: 0,
    });
  }, [enabled, `${pathname}-${hash}`]);
};

export const ScrollContext = createContext<{
  autoScrollEnabled: boolean;
  setAutoScrollEnabled: Dispatch<SetStateAction<boolean>>;
}>({
  autoScrollEnabled: true,
  setAutoScrollEnabled: NO_OP,
});
