import { createContext, useContext } from 'react';
import React from 'react';
import { CheckCircle } from 'ui';
import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from 'apis/fetch';
import { queryClient } from 'apis/client';
import { addSuccessToast } from 'ui/components/Notification';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from 'feature-flags/useFeatureFlags';

type AuthContextType = {
  isAuthenticated: boolean;
  userEmail: string | null;
  requestOtp: (email: string) => Promise<string>;
  verifyOtp: (params: { otpId: string; otp: string }) => Promise<boolean>;
  logout: () => void;
  loginTrainingUser: (params: { email: string; otp: string; otpId?: string }) => Promise<void>;
  registerTrainingUser: (params: {
    name: string;
    firmUen: string;
    firmName: string;
    role: string;
    regNumber: string;
    email: string;
    otp: string;
    otpId: string;
  }) => Promise<void>;
};

type ITrainingUserDetails = {
  name: string;
  email: string;
};

const TrainingEnvAuthContext = createContext<AuthContextType | undefined>(undefined);

export const useTrainingEnvAuth = () => {
  const context = useContext(TrainingEnvAuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const TrainingEnvAuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const { enableTrainingEnv } = useFeatureFlags();

  const { data: trainingUserAccountData, isSuccess } = useQuery({
    enabled: Boolean(enableTrainingEnv),
    queryKey: ['profile'],
    queryFn: async ({ signal }) => api<{ account: { email: string } }>('/otp-authenticate/profile', { signal }),
    retry: false,
  });

  const requestOtpMutation = useMutation({
    mutationFn: async (email: string) => {
      const response = await api<{ otpId: string }>('/otp-authenticate/request-otp/training-users', {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ email }),
      });
      return response.otpId;
    },
  });

  const verifyOtpMutation = useMutation({
    mutationFn: async (params: { otpId: string; otp: string }) => {
      try {
        const { success } = await api<{ success: boolean }>('/otp-authenticate/verify-otp/training-users', {
          headers: { 'content-type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(params),
        });
        return !!success;
      } catch (e) {
        return false;
      }
    },
  });

  const logoutOtpMutation = useMutation({
    mutationFn: async () => {
      await api<{ success: boolean }>('/otp-authenticate/logout-session/training-users', {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: {},
      });
    },
    onSuccess: async () => {
      queryClient.clear();
    },
  });

  const loginTrainingUserMutation = useMutation({
    mutationFn: async (params: { email: string; otp: string; otpId?: string }) => {
      const { success } = await api<{ success: boolean; user: ITrainingUserDetails }>(
        '/training-env/training-users/login',
        {
          headers: { 'content-type': 'application/json' },
          method: 'POST',
          body: {
            ...params,
          },
        },
      );
      if (success) {
        queryClient.invalidateQueries({ queryKey: ['profile'] });
        queryClient.invalidateQueries({ queryKey: ['training-user'] });
      }
    },
    onSuccess: async () => {
      addSuccessToast({
        icon: <CheckCircle />,
        message: `${t('trainingEnv:toast.loginSuccess')}`,
      });
    },
  });

  const registerTrainingUserMutation = useMutation({
    mutationFn: async (params: {
      name: string;
      firmUen: string;
      firmName: string;
      role: string;
      regNumber: string;
      email: string;
      otp: string;
      otpId: string;
    }) => {
      const { success } = await api<{ success: boolean; user: ITrainingUserDetails }>(
        '/training-env/training-users/register',
        {
          headers: { 'content-type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(params),
        },
      );
      if (success) {
        queryClient.invalidateQueries({ queryKey: ['profile'] });
        queryClient.invalidateQueries({ queryKey: ['training-user'] });
      }
    },
    onSuccess: async () => {
      addSuccessToast({
        icon: <CheckCircle />,
        message: `${t('trainingEnv:toast.registerSuccess')}`,
      });
    },
  });

  const isAuthenticated = isSuccess && !!trainingUserAccountData?.account.email;

  return (
    <TrainingEnvAuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        userEmail: trainingUserAccountData?.account.email ?? null,
        requestOtp: requestOtpMutation.mutateAsync,
        verifyOtp: verifyOtpMutation.mutateAsync,
        logout: logoutOtpMutation.mutateAsync,
        loginTrainingUser: loginTrainingUserMutation.mutateAsync,
        registerTrainingUser: registerTrainingUserMutation.mutateAsync,
      }}
    >
      {children}
    </TrainingEnvAuthContext.Provider>
  );
};
