import React from 'react';

import tlds from './tlds.json';
import { MonetaryAmount } from './MonetaryAmount';
import {
  IDeclaredResponsibility,
  IProjectMemberRepresentCompany,
  ISelectedRole,
} from 'pages/Projects/ProjectMembers/interfaces';
import { IRecursiveSelectedValue } from '../components/RecursiveDropdown/RecursiveDropdown';
import { IRemovalRequest, IReplacementRequest } from './interfaces/IRemovalRequest';
import { InfoPanelDetails } from '../pages/Submissions/EditSubmission/useInfoPanelDetails';
import type { IProjectMemberSelectProps } from 'SubmissionForms/FormFieldsBuilder/components';
import { ISiteInspection } from 'pages/SiteInspection/Common';
import { IProjectMemberOrInvitation } from 'hooks/project/useProjectMembers';

export { tlds };

// Additional fees will be incurred for payment > $10,000 (so avoided by BCA)
export const STRIPE_LIMIT = 1000000;

export const CONTACT_HELP_PAGE = 'https://support.corenet.gov.sg/hc/en-us';
export const FILE_EXTNS_SUPPORT_LINK = 'https://support.corenet.gov.sg/hc/en-us/articles/20309488061081';

export const generateFaqLink = (keyword?: string) => {
  return `https://support.corenet.gov.sg/hc/en-us/${keyword ? `search?utf8=%E2%9C%93&query=${keyword}` : ''}`;
};

export enum PAYMENT_TRANSACTION_STATUS {
  PAID = 'Paid',
  PROCESSING = 'Processing',
  FAILED = 'Failed',
  AGENCY_TO_BILL = 'Agency to Bill',
  PAID_EARLIER = 'Paid Earlier',
}

export enum PAYMENT_STATUS {
  DUE = 'Due',
  PAID = 'Paid',
  PROCESSING = 'Processing',
  FAILED = 'Failed',
}

export enum PAYMENT_METHOD {
  DELEGATE = 'Delegate',
  AGENCY = 'Agency',
  PAYNOW = 'PayNow',
  CREDIT_CARD = 'Credit Card',
  BANK_TRANSFER = 'Bank Transfer',
  PAID_EARLIER = 'Paid Earlier',
}

export enum NOTIFICATION_STATE {
  READ = 'Read',
  UNREAD = 'Unread',
}

export enum NOTIFICATION_TYPE {
  // Initial Notification
  PAYMENT_REQUESTED = 'PAYMENT_REQUESTED',
  PROJECT_CREATED_ON_YOUR_BEHALF = 'PROJECT_CREATED_ON_YOUR_BEHALF',
  REVIEW_PROJECT_CREATED_ON_YOUR_BEHALF = 'REVIEW_PROJECT_CREATED_ON_YOUR_BEHALF',

  // Notification Batch 1
  ADDED_AS_ASSISTANT = 'ADDED_AS_ASSISTANT',
  REMOVED_AS_ASSISTANT = 'REMOVED_AS_ASSISTANT',
  MEMBER_ACCEPTED_INVITE = 'MEMBER_ACCEPTED_INVITE',
  MEMBER_REMOVED = 'MEMBER_REMOVED',

  // Notification Batch 2
  RECEIVED_APPOINTMENT_REQUEST = 'RECEIVED_APPOINTMENT_REQUEST',
  NOTIFY_APPOINTED_PROJECT_MEMBER = 'NOTIFY_APPOINTED_PROJECT_MEMBER',
  NOTIFY_INCOMING_PC = 'NOTIFY_INCOMING_PC',
  NOTIFY_OUTGOING_PC_OF_REMOVAL = 'NOTIFY_OUTGOING_PC_OF_REMOVAL',
  NOTIFY_PROJECT_MEMBER_PC_REPLACEMENT = 'NOTIFY_PROJECT_MEMBER_PC_REPLACEMENT',
  NOTIFY_PROJECT_MEMBER_PENDING_REMOVAL = 'NOTIFY_PROJECT_MEMBER_PENDING_REMOVAL',
  NOTIFY_PROJECT_MEMBER_REMOVAL = 'NOTIFY_PROJECT_MEMBER_REMOVAL',
  REMOVE_PROJECT_MEMBER_AUTHORISATION = 'REMOVE_PROJECT_MEMBER_AUTHORISATION',
  REMOVE_PROJECT_COORDINATOR_AUTHORISATION = 'REMOVE_PROJECT_COORDINATOR_AUTHORISATION',
  MEMBER_APPOINTMENT_REMOVED = 'MEMBER_APPOINTMENT_REMOVED',

  // Notification Batch 3
  SUBMISSION_CREATED = 'SUBMISSION_CREATED',
  NOTIFY_APPOINTED_QP = 'NOTIFY_APPOINTED_QP',
  ASSIGNED_TO_SUBMISSION = 'ASSIGNED_TO_SUBMISSION',
  REASSIGNED_TO_SUBMISSION = 'REASSIGNED_TO_SUBMISSION',
  ASSIGNED_SECTION_BACK_TO_DRAFT = 'ASSIGNED_SECTION_BACK_TO_DRAFT',
  SUBMISSION_BACK_TO_DRAFT = 'SUBMISSION_BACK_TO_DRAFT',
  SUBMISSION_SECTION_REVERTED_TO_DRAFT = 'SUBMISSION_SECTION_REVERTED_TO_DRAFT',
  SUBMISSION_SECTION_READY_FOR_REVIEW = 'SUBMISSION_SECTION_READY_FOR_REVIEW',
  SUBMISSION_PENDING_PAYMENT = 'SUBMISSION_PENDING_PAYMENT',
  SUBMISSION_REQUIRES_ATTENTION = 'SUBMISSION_REQUIRES_ATTENTION',
  SUBMISSION_RESPONSE_ISSUED = 'SUBMISSION_RESPONSE_ISSUED',
  WRITTEN_DIRECTION_ISSUED = 'WRITTEN_DIRECTION_ISSUED',
  WRITTEN_DIRECTION_EXTENSION = 'WRITTEN_DIRECTION_EXTENSION',
  WRITTEN_DIRECTION_LAPSED = 'WRITTEN_DIRECTION_LAPSED',
  SUBMISSION_NOT_APPROVED = 'SUBMISSION_NOT_APPROVED',
  SUBMISSION_REAPPLICATION_CREATED = 'SUBMISSION_REAPPLICATION_CREATED',
  SUBMISSION_AMENDMENT_CREATED = 'SUBMISSION_AMENDMENT_CREATED',
  SUBMISSION_SUBMITTED = 'SUBMISSION_SUBMITTED',
  SUBMISSION_CLEARED = 'SUBMISSION_CLEARED',
  PROJECT_DELETION_AUTHORISATION = 'PROJECT_DELETION_AUTHORISATION',
  PROJECT_CLOSURE_AUTHORISATION = 'PROJECT_CLOSURE_AUTHORISATION',
  PROJECT_CLOSED_NOTIFICATION = 'PROJECT_CLOSED_NOTIFICATION',
  PROJECT_DELETED_NOTIFICATION = 'PROJECT_DELETED_NOTIFICATION',
  SUBMISSION_TAKEN_OVER = 'SUBMISSION_TAKEN_OVER',
  SUBMISSION_DELETED = 'SUBMISSION_DELETED',
}

export enum BROADCAST_MESSAGE_TYPE {
  ALERT = 'Alert',
  WARNING = 'Warning',
  INFO = 'Info',
  NEUTRAL = 'Neutral',
}

export enum BROADCAST_MESSAGE_CATEGORY {
  DEFAULT = 'DEFAULT',
  LOGIN = 'LOGIN',
  INCOMPLETE_PROFILE = 'INCOMPLETE_PROFILE',
}

export enum PROJECT_STATUS {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_DELETION = 'PENDING_DELETION',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
}

export enum PROJECT_MEMBER_STATUS {
  PENDING = 'PENDING',
  PENDING_REPLACEMENT = 'PENDING_REPLACEMENT',
}

export enum REGISTRAR_TYPE {
  BOARD_OF_ARCHITECTS = 'BOA',
  PROFESSIONAL_ENGINEER_BOARD = 'PEB',
  BUILDERS_LICENSING_SCHEME = 'BLS',
}

export enum CREATE_PROJECT_FOR {
  MYSELF = 'Myself',
  OTHERS = 'Others',
}

export enum CODE_TABLE {
  ROADS = 'roads',
  MKTS = 'mukim-town-subdivisions',
}

export enum COORDINATOR_ROLE {
  REGISTERED_ARCHITECT = 'Architect',
  PROFESSIONAL_ENGINEER = 'Professional Engineer',
}

export enum ROLE {
  REGISTERED_ARCHITECT = 'registeredArchitect',
  RESIDENT_ENGINEER_CIVIL_AND_STRUCTURE = 'residentEngineerCivilAndStructure',
  RESIDENT_TECHNICAL_OFFICER_CIVIL_AND_STRUCTURE = 'residentTechnicalOfficerCivilAndStructure',
  RESIDENT_ENGINEER_MECHANICAL_AND_ELECTRICAL = 'residentEngineerMechanicalAndElectrical',
  RESIDENT_TECHNICAL_OFFICER_MECHANICAL_AND_ELECTRICAL = 'residentTechnicalOfficerMechanicalAndElectrical',
  PROFESSIONAL_ENGINEER_STRUCTURE = 'professionalEngineerStructure',
  PROFESSIONAL_ENGINEER_CIVIL = 'professionalEngineerCivil',
  PROFESSIONAL_ENGINEER_ELECTRICAL = 'professionalEngineerElectrical',
  PROFESSIONAL_ENGINEER_MECHANICAL = 'professionalEngineerMechanical',
  PLUMBING_CONTRACTOR = 'plumbingContractor',
  LICENSED_PLUMBER = 'licensedPlumber',
  LICENSED_WATER_SERVICE_PLUMBER = 'licensedWaterServicePlumber',
  DEVELOPER = 'developer',
  DEVELOPER_OWNER = 'developerOwner',
  DEVELOPER_COMPANY = 'developerCompany',
  DEVELOPER_COMPANY_MAIN = 'developerCompanyMain',
  DEVELOPER_COMPANY_SECONDARY = 'developerCompanySecondary',
  LICENSED_BUILDER = 'licensedBuilder',
  APPROVED_PERSON = 'approvedPerson',
  TECHNICAL_CONTROLLER = 'technicalController',
  LICENSED_SPECIALIST_BUILDER = 'licensedSpecialistBuilder',
  APPROVED_PERSON_SPECIALIST_BUILDER = 'approvedPersonSpecialistBuilder',
  TECHNICAL_CONTROLLER_SPECIALIST_BUILDER = 'technicalControllerSpecialistBuilder',
  SPECIALIST_PROFESSIONAL_ENGINEER_GEOTECHNICAL = 'specialistProfessionalEngineerGeotechnical',
  SPECIALIST_PROFESSIONAL_ENGINEER_LIFT_AND_ESCALATORS = 'specialistProfessionalEngineerLiftAndEscalators',
  SPECIALIST_ACCREDITED_CHECKER = 'specialistAccreditedChecker',
  ACCREDITED_CHECKER = 'accreditedChecker',
  FIRE_SAFETY_ENGINEER = 'fireSafetyEngineer',
  REGISTERED_SURVEYOR = 'registeredSurveyor',
  REGISTERED_INSPECTOR_ARCHITECTURE = 'registeredInspectorArchitecture',
  REGISTERED_INSPECTOR_MECHANICAL_AND_ELECTRICAL = 'registeredInspectorMechanicalAndElectrical',
  TRANSPORT_CONSULTANT = 'transportConsultant',
}

export const RESIDENT_ENGINEERING_ROLES = [
  ROLE.RESIDENT_ENGINEER_CIVIL_AND_STRUCTURE,
  ROLE.RESIDENT_ENGINEER_MECHANICAL_AND_ELECTRICAL,
];

export enum QP_BASED_ROLES {
  RESIDENT_ENG_ROLE = 'qp-based-resident-eng',
}

export enum PROFESSIONAL_NUMBER_TYPE {
  REGISTRATION_NUMBER = 'REGISTRATION_NUMBER',
  LICENSE_NUMBER = 'LICENSE_NUMBER',
  UEN = 'UEN',
}

export enum SUBMISSION_STATE {
  DRAFT = 'DRAFT',
  PENDING_DECLARATION = 'PENDING_DECLARATION',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  SUBMITTED = 'SUBMITTED',
  WRITTEN_DIRECTION_ISSUED = 'WRITTEN_DIRECTION_ISSUED',
  WRITTEN_DIRECTION_LAPSED = 'WRITTEN_DIRECTION_LAPSED',
  CLEARANCE = 'CLEARANCE',
  NOT_APPROVED = 'NOT_APPROVED',
  //orphaned state is used for Resubmissions which were in draft/pending declaration/pending payment when their parent submission expired. This is triggered by SendWrittenDirectionExpiredEmail delayed job
  ORPHANED = 'ORPHANED',
  DOCUMENT_SUBMITTED = 'DOCUMENT_SUBMITTED',
  RESPONSE_ISSUED = 'RESPONSE_ISSUED',
  // Revoked is only for BCA permit so far
  REVOKED = 'REVOKED',
  WITHDRAWN = 'WITHDRAWN',
}

/** Corresponding display values for `SUBMISSION_STATE` enum. */
export const SUBMISSION_STATE_DISPLAY: Record<SUBMISSION_STATE, string> = {
  [SUBMISSION_STATE.DRAFT]: 'Draft',
  [SUBMISSION_STATE.PENDING_DECLARATION]: 'Pending declaration',
  [SUBMISSION_STATE.PENDING_PAYMENT]: 'Pending payment',
  [SUBMISSION_STATE.SUBMITTED]: 'Processing',
  [SUBMISSION_STATE.DOCUMENT_SUBMITTED]: 'Submitted',
  [SUBMISSION_STATE.WRITTEN_DIRECTION_ISSUED]: 'Written Direction issued',
  [SUBMISSION_STATE.WRITTEN_DIRECTION_LAPSED]: 'Written Direction lapsed',
  [SUBMISSION_STATE.CLEARANCE]: 'Clearance',
  [SUBMISSION_STATE.RESPONSE_ISSUED]: 'Response issued',
  [SUBMISSION_STATE.NOT_APPROVED]: 'Not approved',
  [SUBMISSION_STATE.ORPHANED]: 'Orphaned',
  [SUBMISSION_STATE.REVOKED]: 'Revoked',
  [SUBMISSION_STATE.WITHDRAWN]: 'Withdrawn',
};

export enum SUBMISSION_FORM_SECTION_STATE {
  DRAFT = 'DRAFT',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  PENDING_DECLARATION = 'PENDING_DECLARATION',
  DECLARED = 'DECLARED',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  SUBMITTED = 'SUBMITTED',
}

export enum RECURSIVE_DROPDOWN_TYPE {
  SUBMISSION_CATEGORY = 'submission-category',
  SUBMISSION = 'submission',
  AGENCY_FOR_SUBMISSION = 'agency-for-submission',
  AGENCY = 'agency',
  TYPE_OF_INDEPENDENT_SUBMISSION = 'type-of-independent-submission',
  AGENCY_CATEGORY = 'agency-category',
  WAIVER_SUBMISSION = 'waiver-submission',
  APPLICATION_FOR = 'application-for',
  EXISTING_PERMIT = 'existing-permit',
  QUALIFIED_PERSON = 'qualified-person',
  SUBMISSION_TYPE = 'submission-type',
}

export enum SUBMISSION_CATEGORY {
  PERMIT_TO_COMMENCE_WORKS = 'permit-to-commence-works',
  INDEPENDENT_SUBMISSION = 'independent-submission',
}

export enum SUBMISSION_CODES {
  // Mock submission code for regression testing
  MOCK_SUBMISSION = 'mock-submission',

  // DSP plan applications
  PLAN_APPLICATION = 'plan-application',
  PLAN_APPLICATION_3D_BIM = 'plan-application-3d-bim',
  PLAN_APPLICATION_WITH_A_AND_A = 'plan-application-with-additions-and-alterations',
  PLAN_APPLICATION_WITH_A_AND_A_3D_BIM = 'plan-application-with-additions-and-alterations-3d-bim',

  PLAN_APPLICATION_STANDALONE = 'plan-application-standalone',
  PLAN_APPLICATION_STANDALONE_3D_BIM = 'plan-application-standalone-3d-bim',
  PLAN_APPLICATION_STANDALONE_WITH_A_AND_A = 'plan-application-standalone-with-additions-and-alterations',
  PLAN_APPLICATION_STANDALONE_WITH_A_AND_A_3D_BIM = 'plan-application-standalone-with-additions-and-alteration-3d-bim',

  // Other development types
  PLAN_APPLICATION_OTHER_DEVS = 'plan-application-other-devs',
  PLAN_APPLICATION_OTHER_DEVS_3D_BIM = 'plan-application-other-devs-3d-bim',

  EW_PLAN_APPLICATION = 'ew-plan-application',

  PERMIT_TO_CARRY_OUT_STRUCTURAL_WORKS = 'permit-to-carry-out-structural-works',
  PERMIT_LTA_ROADS = 'permit-lta-roads',
  PERMIT_LTA_RAILS = 'permit-lta-rails',
  FULL_DEMOLITION_APPLICATION = 'full-demolition-application',

  //Placeholder for completion of structural of works - completion for demolition gateway
  COMPLETION_OF_STRUCTURAL_WORKS = 'completion-of-structural-works',

  //Placeholder for 3.5 Gateway plan application
  DG_PLAN_APPLICATION = 'dg-plan-application',
  PG_PLAN_APPLICATION = 'pg-plan-application',
  CG_PLAN_APPLICATION = 'cg-plan-application',

  //Form name for 3.5 gateway plan application with bim, NOT a submission code
  DG_PLAN_APPLICATION_3D_BIM = 'dg-plan-application-3d-bim',
  PG_PLAN_APPLICATION_3D_BIM = 'pg-plan-application-3d-bim',
  CG_PLAN_APPLICATION_3D_BIM = 'cg-plan-application-3d-bim',

  //Form name for 3.5 gateway plan application that is A&A without bim, NOT a submission code
  DG_PLAN_APPLICATION_AA = 'dg-plan-application-aa',
  PG_PLAN_APPLICATION_AA = 'pg-plan-application-aa',
  CG_PLAN_APPLICATION_AA = 'cg-plan-application-aa',

  //Form name for 3.5 gateway plan application that is A&A with bim, NOT a submission code
  DG_PLAN_APPLICATION_AA_3D_BIM = 'dg-plan-application-aa-3d-bim',
  PG_PLAN_APPLICATION_AA_3D_BIM = 'pg-plan-application-aa-3d-bim',
  CG_PLAN_APPLICATION_AA_3D_BIM = 'cg-plan-application-aa-3d-bim',

  STRUCTURAL_PLAN_AND_PERMIT_APPLICATION = 'structural-plan-and-permit-application',
  CERTIFICATE_OF_DESIGN_AND_SUPERVISION_OF_LIGHTNING_PROTECTION_SYSTEM = 'certificate-of-design-and-supervision-of-lightning-protection-system',
  CERTIFICATE_OF_SUPERVISION_OF_BUILDING_WORKS = 'certificate-of-supervision-of-building-works',
  CERTIFICATE_OF_SUPERVISION_OF_INSTALLATION_OF_LIFT_ESCALATOR = 'certificate-of-supervision-of-installation-of-lift-escalator',
  TECHNICAL_CLEARANCE_BCA_CERT_OF_SUPERVISION_ACMV = 'tech-clearance-bca-cos-acmv',
  TECHNICAL_CLEARANCE_BCA_CERT_OF_SUPERVISION_LPS = 'tech-clearance-bca-cos-lps',

  AS_BUILT_FOR_ENVIRONMENTAL_SUSTAINABILITY = 'as-built-for-environmental-sustainability-documentation',
  AS_BUILT_FOR_BUILDABILITY = 'as-built-for-buildable-design-score-calculations',
  AS_BUILT_FOR_CONSTRUCTABILITY = 'as-built-for-constructability-score-calculations',

  TOP_CLEARANCE_BCA_OVERALL = 'top-clearance-bca-overall',
  TOP_CLEARANCE_BCA_OVERALL_ST_WORKS = 'top-clearance-bca-overall-st-works',
  TOP_CLEARANCE_URA_PLANNING_PARAMETERS = 'top-clearance-ura-planning-parameters',
  TOP_CLEARANCE_PUB_DRAINAGE = 'top-clearance-pub-drainage',
  TOP_CLEARANCE_NEA_ENVIRONMENT_HEALTH_AND_POLLUTION_CONTROL = 'top-clearance-nea-environment-health-and-pollution-control',
  TOP_CLEARANCE_SCDF_FIRE_SAFETY = 'top-clearance-scdf-fire-safety',
  TOP_CLEARANCE_BCA_BUILDING_CONTROL = 'top-clearance-bca-building-control',

  CSC_CLEARANCE_BCA_OVERALL = 'csc-clearance-bca-overall',
  CSC_CLEARANCE_URA_PLANNING_PARAMETERS = 'csc-clearance-ura-planning-parameters',
  CSC_CLEARANCE_NPARKS_GREENERY_PROVISION = 'csc-clearance-nparks-greenery-provision',
  CSC_CLEARANCE_LTA_ROADS = 'csc-clearance-lta-roads',
  CSC_CLEARANCE_LTA_RAILS = 'csc-clearance-lta-rails',
  CSC_CLEARANCE_LTA_VEHICLE_PARKING = 'csc-clearance-lta-vehicle-parking',
  CSC_CLEARANCE_PUB_DRAINAGE = 'csc-clearance-pub-drainage',
  CSC_CLEARANCE_PUB_SEWERAGE_AND_SANITARY = 'csc-clearance-pub-sewerage-and-sanitary',
  CSC_CLEARANCE_NEA_ENVIRONMENT_HEALTH_AND_POLLUTION_CONTROL = 'csc-clearance-nea-environment-health-and-pollution-control',
  CSC_CLEARANCE_SCDF_FIRE_SAFETY = 'csc-clearance-scdf-fire-safety',
  CSC_CLEARANCE_BCA_BUILDING_CONTROL = 'csc-clearance-bca-building-control',

  TECHNICAL_CLEARANCE_RECORD_PLAN_FOR_BUILDING_WORKS_BCA = 'tech-clearance-record-plan-for-building-works-bca',
  TECHNICAL_CLEARANCE_BCA_BUILDER_CERT_COMPLETION_BUILDING_WORKS = 'tech-clearance-bca-builder-cert-completion-building-works',
  TECHNICAL_CLEARANCE_BCA_COMMISSION_TEST_REPORT_PUBLIC_TRANSIT_SHELTER = 'tech-clearance-bca-commission-test-report-public-transit-shelter',
  TECHNICAL_CLEARANCE_BCA_NOTICE_OF_APPROVAL_OF_COMMISSIONING = 'technical-clearance-bca-notice-of-approval-of-commissioning',
  TECHNICAL_CLEARANCE_BCA_RECORD_PLAN_FOR_FIXED_INSTALLATION_WORKS = 'tech-clearance-bca-record-plan-for-fixed-installation-works',
  TECHNICAL_CLEARANCE_BCA_NOTIFICATION_OF_COMMISSIONING_TEST_FOR_CIVIL_DEFENCE_SHELTER = 'tech-clearance-bca-notification-of-commissioning-test-for-civil-defence-shelter',

  PRE_SUBMISSION_CONSULTATION = 'pre-submission-consultation',

  PRE_SUBMISSION_CONSULTATION_SUBMISSION_REQUIREMENTS = 'pre-submission-consultation-submission-requirements',
  PRE_SUBMISSION_CONSULTATION_TOPCSC_REQUIREMENTS = 'pre-submission-consultation-topcsc-requirements',
  PRE_SUBMISSION_CONSULTATION_PARTIAL_TOP = 'pre-submission-consultation-partial-top',
  PRE_SUBMISSION_CONSULTATION_OTHERS = 'pre-submission-consultation-others',
  PRE_SUBMISSION_CONSULTATION_AGENCY_SPECIFIC_REQUIREMENTS = 'pre-submission-consultation-agency-specific-requirements',

  SUBMIT_DOCUMENT_BCA = 'submit-document-bca',
  SUBMIT_DOCUMENT_LTA = 'submit-document-lta',
  SUBMIT_DOCUMENT_URA = 'submit-document-ura',
  SUBMIT_DOCUMENT_NParks = 'submit-document-nparks',
  SUBMIT_DOCUMENT_PUB = 'submit-document-pub',
  SUBMIT_DOCUMENT_SCDF = 'submit-document-scdf',
  SUBMIT_DOCUMENT_NEA = 'submit-document-nea',
  SUBMIT_DOCUMENT_JTC = 'submit-document-jtc',
  SUBMIT_DOCUMENT_HDB = 'submit-document-hdb',

  APPLICATION_FOR_TOP_CLEARANCE = 'application-for-TOP-clearance',
  APPLICATION_FOR_CSC_CLEARANCE = 'application-for-CSC-clearance',

  WAIVER_NON_COMPLIANCE = 'waiver-non-compliance',
  WAIVER_BCA_BUILDING_DESIGN = 'waiver-bca-building-design',
  WAIVER_BCA_STRUCTURAL_WORKS = 'waiver-bca-structural-works',
  WAIVER_BCA_LIGHTNING_PROTECTION_SYSTEM = 'waiver-bca-lightning-protection-system',
  WAIVER_BCA_FIXED_INSTALLATION = 'waiver-bca-fixed-installation',
  WAIVER_URA_PLANNING_PARAMETERS = 'waiver-ura-planning-parameters',
  WAIVER_NEA_ENVIRONMENTAL_HEALTH = 'waiver-nea-environmental-health',
  WAIVER_NEA_POLLUTION_CONTROL = 'waiver-nea-pollution-control',
  WAIVER_PUB_SANITARY_WORKS = 'waiver-pub-sanitary-works',
  WAIVER_PUB_SEWERAGE_WORKS = 'waiver-pub-sewerage-works',
  WAIVER_PUB_SEWERAGE_SANITARY = 'waiver-pub-sewerage-sanitary',
  WAIVER_PUB_USED_WATER_PUMPING_SYSTEM = 'waiver-pub-used-water-pumping-system',
  WAIVER_PUB_WATER_SERVICE_INSTALLATION_WORKS = 'waiver-pub-water-service-installation-works',
  WAIVER_PUB_RC_TRENCH = 'waiver-pub-rc-trench',
  WAIVER_PUB_DRAINAGE = 'waiver-pub-drainage',
  WAIVER_SCDF_CD_SHELTER = 'waiver-scdf-cd-shelter',
  WAIVER_SCDF_FIRE_SAFETY = 'waiver-scdf-fire-safety',
  WAIVER_LTA_VEHICULAR_PARKING = 'waiver-lta-vehicular-parking',

  INDEPENDENT_SUB_NPARKS_INTERNAL_PLANTING_SCHEME = 'independent-sub-nparks-internal-planting-scheme',
  INDEPENDENT_SUB_NPARKS_EXTERNAL_PLANTING_SCHEME = 'independent-sub-nparks-external-planting-scheme',
  INDEPENDENT_SUB_NPARKS_PARK_CONNECTOR_PLANTING_SCHEME = 'independent-sub-nparks-park-connector-planting-scheme',
  INDEPENDENT_SUB_NPARKS_SITE_CLEARANCE_EARTHWORKS = 'independent-sub-nparks-site-clearance-earthworks',
  INDEPENDENT_SUB_SCDF_FIRE_PROTECTION_PLAN = 'independent-sub-scdf-fire-protection-plan',
  INDEPENDENT_SUB_SCDF_ACMV = 'independent-sub-scdf-acmv',
  INDEPENDENT_SUB_PERFORMANCE_BASED_PLAN = 'independent-sub-performance-based-plan',
  INDEPENDENT_SUB_PUB_WATER_SERVICE_COMPLETION_SATISFACTION_CERTIFICATE = 'independent-sub-pub-water-service-completion-satisfaction-certificate',
  INDEPENDENT_SUB_PUB_NOTIFICATION_OF_WATER_SERVICE_INSTALLATION_WORKS = 'independent-sub-pub-notification-of-water-service-installation-works',
  INDEPENDENT_SUB_PUB_MANDATORY_WATER_EFFICIENCY_REQUIREMENTS = 'independent-sub-pub-mandatory-water-efficiency-requirements',
  INDEPENDENT_SUB_PUB_WORKS_IN_PUBLIC_SEWERAGE_SYSTEM = 'independent-sub-pub-works-in-public-sewerage-system',
  INDEPENDENT_SUB_INSTRUMENTATION_MONITORING_REPORT = 'independent-sub-instrumentation-monitoring-report',
  INDEPENDENT_SUB_URA_EARTHWORKS = 'independent-sub-ura-earthworks',
  INDEPENDENT_SUB_BCA_CONSTRUCTABILITY_SCORE = 'independent-sub-bca-constructability-score',
  INDEPENDENT_SUB_LTA_RAILS_INSTRUMENTATION_MONITORING_REPORT = 'independent-sub-lta-rails-instrumentation-monitoring-report',
  INDEPENDENT_SUB_LTA_APPLICATION_FOR_TAKING_OVER_STREETS = 'independent-sub-lta-application-for-taking-over-streets',
  INDEPENDENT_SUB_LTA_TRANSPORT_IMPACT_ASSESSMENT = 'independent-sub-lta-transport-impact-assessment',
  INDEPENDENT_SUB_LTA_AS_BUILT_FOR_COM = 'independent-sub-lta-as-built-for-com',
  INDEPENDENT_SUB_LTA_NOTIFICATION_FOR_NEW_STREET_TRAFFIC = 'independent-sub-notification-for-new-street-traffic',
  INDEPENDENT_SUB_URA_SUB_DIVISION = 'independent-sub-ura-sub-division',
  INDEPENDENT_SUB_BCA_CIVIL_DEFENCE_SHELTER = 'independent-sub-bca-civil-defence-shelter',
  INDEPENDENT_SUB_NEA_TEMPORARY_SANITARY_FACILITIES = 'independent-sub-nea-temporary-sanitary-facilities',

  TECHNICAL_CLEARANCE_SCDF_FIRE_SAFETY_WORKS_MAA = 'tech-clearance-scdf-fire-safety-works-maa',
  INDEPENDENT_SUB_NEA_POLLUTION_CONTROL_EQUIPMENT = 'independent-sub-nea-pollution-control-equipment',
  INDEPENDENT_SUB_NEA_NOISE_ASSESSMENT = 'independent-sub-nea-noise-assessment',
  INDEPENDENT_SUB_UPDATE_PROJECT_ADDRESS_ISSUED_BY_IRAS = 'independent-sub-update-project-address-issues-by-iras',

  INDEPENDENT_SUB_JTC_LANDOWNER_CONSENT = 'independent-sub-jtc-landowner-consent',
  INDEPENDENT_SUB_BCA_FIXED_INSTALLATION_PLAN = 'independent-sub-bca-fixed-installation-plan',

  INDEPENDENT_SUB_BCA_CIVIL_DEFENCE_SHELTER_PLAN = 'independent-sub-civil-defence-shelter-plan-public-transit-shelter',
  METHOD_STATEMENT_FOR_COMMISSIONING_TEST = 'method-statement-for-commissioning-test',

  INDEPENDENT_SUB_HDB_ELECTRICAL_PLAN = 'independent-sub-hdb-electrical-plan',
  INDEPENDENT_SUB_HDB_MECHANICAL_PLAN = 'independent-sub-hdb-mechanical-plan',
  INDEPENDENT_SUB_HDB_APPLICATION_FOR_CS_PLANS = 'independent-sub-hdb-application-for-cs-plans',
  INDEPENDENT_SUB_HDB_APPLICATION_FOR_CS_AS_BUILT_PLANS = 'independent-sub-hdb-application-for-cs-as-built-plans',
  INDEPENDENT_SUB_HDB_ARCHITECTURE_PLAN = 'independent-sub-hdb-architecture-plan',
  INDEPENDENT_SUB_HDB_COMPLIANCE_CERTIFICATE_ARCHITECTURE_PLAN = 'independent-sub-hdb-compliance-certificate-architecture-plan',

  // For Temporary building works - CX-5080, CX-5087
  DEVELOPMENT_APPLICATION_TO_URA = 'development-application-to-ura',
  PERMIT_TO_USE_TEMP_BUILDING_PRELIM_APPROVAL_NEW_ERECTION = 'permit-to-use-prelim-approval-new-erection',
  PERMIT_TO_USE_TEMP_BUILDING_PERMIT_TO_USE_NEW_ERECTION = 'permit-to-use-temp-building-new-erection',
}

export const FOR_ADDITIONS_AND_ALTERATIONS = 'This submission is for Additions & Alterations (A&A)';

export const SUBMISSION_CATEGORY_DISPLAY = {
  'permit-to-commence-works': 'Permit application (to carry out works)',
  'plan-application-category': 'Plan application (Gateways/Direct Submission/Full demolition)',
  'independent-submission': 'Independent submission (to individual agencies)',
  'technical-clearance': 'Technical clearances (certificate of supervision etc.)',
  'top-csc': 'TOP/CSC',
  'pre-submission-consultation': 'Pre-submission consultation',
  'waiver-non-compliance': 'Waiver/non-compliance',
};

export enum SUBMISSION_EVENT {
  SUBMISSION_CREATED = 'submission-created',
  PENDING_DECLARATION = 'pending-declaration',
  PENDING_PAYMENT = 'pending-payment',
  SUBMISSION_SUBMITTED = 'submission-submitted',
  SUBMISSION_CLEARED = 'submission-cleared',
  WRITTEN_DIRECTION_ISSUED = 'written-direction-issued',
  WRITTEN_DIRECTION_LAPSED = 'written-direction-lapsed',
  RESUBMISSION_CREATED = 'resubmission-created',
  RESUBMISSION_PENDING_DECLARATION = 'resubmission-pending-declaration',
  RESUBMISSION_PENDING_PAYMENT = 'resubmission-pending-payment',
  RESUBMISSION_SUBMITTED = 'resubmission-submitted',
  RESUBMISSION_CLEARED = 'resubmission-cleared',
  AMENDMENT_CREATED = 'amendment-created',
  AMENDMENT_PENDING_DECLARATION = 'amendment-pending-declaration',
  AMENDMENT_PENDING_PAYMENT = 'amendment-pending-payment',
  AMENDMENT_SUBMITTED = 'amendment-submitted',
  AMENDMENT_CLEARED = 'amendment-cleared',
  REAPPLICATION_CREATED = 'reapplication-created',
  REAPPLICATION_PENDING_DECLARATION = 'reapplication-pending-declaration',
  REAPPLICATION_PENDING_PAYMENT = 'reapplication-pending-payment',
  REAPPLICATION_SUBMITTED = 'reapplication-submitted',
  REAPPLICATION_CLEARED = 'reapplication-cleared',
  NOT_APPROVED = 'not-approved',
  DOCUMENT_SUBMITTED = 'document-submitted',
  RESPONSE_ISSUED = 'response-issued',
  REVOKED = 'revoked',
  SUBMISSION_WITHDRAWN = 'submission-withdrawn',
  RESUBMISSION_WITHDRAWN = 'resubmission-withdrawn',
  AMENDMENT_WITHDRAWN = 'amendment-withdrawn',
  SUBMISSION_REVERTED_AFTER_WITHDRAWAL = 'submission-reverted-after-withdrawal',
  RESUBMISSION_REVERTED_AFTER_WITHDRAWAL = 'resubmission-reverted-after-withdrawal',
  AMENDMENT_REVERTED_AFTER_WITHDRAWAL = 'amendment-reverted-after-withdrawal',
}

export enum SUBMISSION_SCHEME {
  PLAN_APPLICATION = 'plan-application',
  LODGEMENT = 'lodgement',
  MAA = 'maa',
  NOT_REQUIRED = 'not-required',
  PRIOR_APPROVAL = 'prior-approval',
  NO_LONGER_REQUIRED = 'no-longer-required',
}

export enum SUBMISSION_SCHEME_DISPLAY_NAME {
  PLAN_APPLICATION = 'planApplication',
  LODGEMENT = 'lodgement',
  SELF_DECLARATION = 'selfDeclaration',
  SIMPLIFIED = 'simplified',
  MAA = 'maa',
}

export enum RECEIVED_STATUS {
  UPLOADING = 'UPLOADING',
  PENDING_FILE_SCAN = 'PENDING_FILE_SCAN',
  TRANSFER_QUEUED = 'TRANSFER_QUEUED',
  PENDING_EXTRACTION = 'PENDING_EXTRACTION',
  FAILED_EXTRACTION = 'FAILED_EXTRACTION',
  FAILED_FILE_SCAN = 'FAILED_FILE_SCAN',
  SUCCEEDED = 'SUCCEEDED',
}

export enum ATTACHMENT_CHANGE_STATUS {
  UNCHANGED = 'UNCHANGED',
  REPLACED = 'REPLACED',
  NEW = 'NEW',
  TAGS_UPDATED = 'TAGS_UPDATED',
}

export enum DEVELOPMENT_TYPE {
  RESIDENTIAL_LANDED = 'residential-landed',
  RESIDENTIAL_NON_LANDED = 'residential-non-landed',
  COMMERCIAL = 'commercial',
  INDUSTRIAL = 'industrial',
  AGRICULTURE = 'agriculture',
  INSTITUTIONAL = 'institutional',
  HEALTHCARE = 'healthcare',
  CIVIL_ENGINEERING_WORKS = 'civil-engineering-works',
  STANDALONE_STRUCTURE = 'standalone-structure',
  TRANSPORT_STATIONS = 'transport-stations',
  TEMPORARY_BUILDING_STRUCTURE = 'temporary-building-structure',
  MIXED_DEVELOPMENT = 'mixed-development',
  OTHERS = 'others',
}

export const OTHER_DEVS = [
  DEVELOPMENT_TYPE.RESIDENTIAL_NON_LANDED,
  DEVELOPMENT_TYPE.AGRICULTURE,
  DEVELOPMENT_TYPE.CIVIL_ENGINEERING_WORKS,
  DEVELOPMENT_TYPE.COMMERCIAL,
  DEVELOPMENT_TYPE.HEALTHCARE,
  DEVELOPMENT_TYPE.INDUSTRIAL,
  DEVELOPMENT_TYPE.INSTITUTIONAL,
  DEVELOPMENT_TYPE.MIXED_DEVELOPMENT,
  DEVELOPMENT_TYPE.TRANSPORT_STATIONS,
  DEVELOPMENT_TYPE.OTHERS,
];

export enum BUILDING_WORKS {
  NEW_ERECTION = 'new-erection',
  ADDITIONS_AND_ALTERATIONS = 'additions-and-alterations',
  DEMOLITION_ONLY = 'demolition-only',
}

export const DEFAULT_BUILDING_WORKS_VALUE = {
  [DEVELOPMENT_TYPE.RESIDENTIAL_LANDED]: '',
  [DEVELOPMENT_TYPE.STANDALONE_STRUCTURE]: BUILDING_WORKS.DEMOLITION_ONLY,
};

export interface IStreetAddress {
  postalCode?: string;
  roadName?: string;
  buildingName?: string;
  houseNumber?: string;
  levelNumber?: string;
  unitNumber?: string;
  unformatted?: string;
  others?: string;
}

export interface IAccount {
  uuid: string;
  person: IPerson;
  company?: ICompany;
  isBusiness?: boolean;
  emailAddress: string;
  designation?: string;
  assistants?: IAssistant[];
  projectMemberships: IProjectMember[];
}

export interface IPerson {
  name?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  overseasNumber?: string;
  myinfoEmail?: string;
  address?: IStreetAddress;
  myinfoRetrieved?: boolean;
  maskedId?: string;
  pictureLink?: string;
  professionalLicences?: IProfessionalLicence[];
  isRegisteredArchitect?: boolean;
  isProfessionalEngineer?: boolean;
  uuid?: string;
}

export interface ICompany {
  uen: string;
  name?: string;
  address?: IStreetAddress;
}

export interface IProjectMember {
  uuid: string;
  emailAddress?: string;
  account: IAccount;
  roles?: IMemberRole[];
  addedBy?: IProjectMember;
  company?: IProjectMemberRepresentCompany;
  deletedAt?: string;
  pendingRemoval?: boolean;
  removalRequest?: IRemovalRequest;
  replacementRequest?: IReplacementRequest[];
}

export interface IMemberRole {
  uuid: string;
  role: IRole;
  responsibilities?: IMemberResponsibility[];
  professionalNumber: string;
  appointments?: {
    agency?: IAgency;
    dateOfAppointment: Date;
    role: IRole;
    appointer?: IProjectMember;
  }[];
  member?: IProjectMember;
  meta?: {
    subRoles: ROLE[];
    developerIsOwner?: boolean;
  };
  deletedAt?: string;
}

export interface IMemberResponsibility {
  uuid?: string;
  responsibility: IResponsibility;
  specify?: string;
  appointment?: {
    agency?: IAgency;
    dateOfAppointment: Date;
    role: IRole;
    appointer?: IProjectMember | null;
    declaration?: { body: DeclarationFormItem[] };
  };
  deletedAt?: Date;
}

export interface IProjectMemberInvitation {
  uuid: string;
  maskedId: string;
  name: string;
  emailAddress: string;
  firmName?: string;
  uen?: string;
  roles?: IMemberRole[];
  deletedAt?: Date;
  addedBy?: IProjectMember;
}

export interface IRegistration {
  regNumber: string;
}

export interface IProfessionalLicence {
  registrar: string;
  regNumber: string;
  valid?: Date;
  specialty?: string;
}

export interface IDismissStatus {
  projectGuide: boolean | null;
  projectCoordinatorNotification: boolean | null;
}

export interface ICurrentAccountProjectInfo {
  uuid: string;
  bookmarked: boolean;
  recentView?: Date;
  memberEmail?: string;
  dismissStatus: IDismissStatus;
}

export interface IProjectDetails {
  isGovt: boolean | null;
  bimSubmission: boolean | null;
  developmentType?: DEVELOPMENT_TYPE;
  buildingWorks?: BUILDING_WORKS;
  gateway?: GATEWAY;
  isPendingGatewayProject?: boolean;

  siteDescription?: string;
  roads: IRoad[];
  formattedAddresses?: { uuid?: string; address: IStreetAddress }[];
  mukimTownSubdivisions?: IProjectMukimTownSubdivision[];
  hasMkTSLotNo?: boolean;

  siteArea?: number;
  grossPlotRatio?: number;
  GFA?: number;
  SGFA?: number;
  storeys?: number;
  basement?: number;
  buildingHeight?: number;
  isCscObtained?: boolean;
}

export interface IProject {
  uuid: string;
  title: string;
  refNumber: string;
  developmentType?: string;
  buildingWorks?: string;
  details?: IProjectDetails;
  createdDate?: Date;
  completionDate?: Date;
  withdrawnDate?: Date;
  status: PROJECT_STATUS;
  members?: IProjectMember[];
  membersPending?: IProjectMemberInvitation[];
  projectCoordinator?: IProjectMember | null;
  createdBy: IAccount | null;
  creationPortal: 'CN2' | 'CX';
  isLoading?: boolean;
}

export interface IRoad {
  code: string;
  description: string;
}

export interface IMukimTownSubdivision {
  code: string;
  description: string;
}

export interface IProjectMukimTownSubdivision {
  uuid: string;
  mukimTownSubdivision: IMukimTownSubdivision;
  lotNo: string;
  plotNo: string;
  partialLot?: boolean;
  createdBy: IAccount | null;
}

export interface IProjectWithAccountInfo extends IProject {
  currentAccount?: ICurrentAccountProjectInfo | null;
}

export type SectionKey = string;

export const SectionKeys = {
  ProjectInfo: 'project-info',
  CoordinatedBim: 'coordinated-bim',
  PlansView: 'plans-view',
  AmendmentPlansView: 'amendment-plans-view',
  FeeComputation: 'fee-computation',
  SupervisionOfGeotechnicalWorks: 'supervision-of-geotechnical-works',
  StructuralWorks: 'structural-works',
  PlanningParameters: 'planning-parameters',
  PermitDetails: 'permit-details',
  GeotechnicalBuildingWorks: 'geotechnical-building-works',
  SupervisionOfStructuralWorks: 'supervision-of-structural-works',
  ManpowerConstructabilitySpecialistBuildingWorks: 'manpower-constructability-specialist-building-works',
  AccreditedCheckerCertificate: 'accredited-checker-certificate',
  SpecialistAccreditedCheckerCertificate: 'specialist-accredited-checker-certificate',
};

export interface IForm {
  name: string;
  version: string;
  structureJson: IFormStructure;
}

type FormDataSectionsRequiredForApproval = Record<SectionKey, { required: boolean }>;

export interface IFormData {
  sectionStatus: Record<string, SUBMISSION_FORM_SECTION_STATE>;
  submissionScheme?: SubmissionSchemeType;
  submissionData: RecordStringAny;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;

  submissionDetails?: RecordStringAny;
  submissionNotRequired?: ISubmissionNotRequired;
  submissionLastUpdatedBy?: Record<string, SubmissionLastUpdated>;
  sectionDeclaredBy?: SectionDeclaredBy;
  submissionUpdatedOn?: Date;
  declarationNotRequiredSections?: string[];
  creationData?: IRecursiveSelectedValue[];
  referencedFromId?: string;
  sectionsAffectedByBim?: string[];
  reassignableSections?: string[];
  qps?: Record<string, string[]>;
  /**
   * sectionsRequiredForApproval should only exist when select sections is involved during creating submission
   *
   * `required` indicate a section is required or not;
   * `hidden` indicate a section should hide from assign project party or not (e.g. Geotechnical Building Works in PG/CG)
   */
  sectionsRequiredForApproval?: FormDataSectionsRequiredForApproval;
  designatedMembers?: Record<string, string>;
}

export type SectionDeclaredBy = { [sectionKey: string]: IDeclarationMemberInfo[] };

export interface IDeclarationMemberInfo {
  ableToDeclare: string[];
  // The value of declaredBy is the user's Account uuid
  declaredBy: string;
  declaredOn: Date;
  declared: boolean;
  identity: DeclIdentity;
}

export interface ISubmissionNotRequired {
  sectionStatus: Record<string, SUBMISSION_FORM_SECTION_STATE>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: SectionKey]: any;
}

export interface ISectionsRequiredForApproval {
  sectionSelector: IField;
  additionalDetails?: IField[];
}

export type ProjectPartyAssignSelector = IField & { props: IProjectMemberSelectProps };

export interface IFormStructure {
  sectionStructure: ISectionStructure[];
  sectionContent: ISectionContent[];
  submissionSchemeQuestionnaire?: ISubmissionSchemeQuestionnaire[];
  submissionDetailsSelection?: ISubmissionDetailsSelection[];
  requiredForApproval?: ISectionsRequiredForApproval;
  projectPartyAssignSelector?: Record<SectionKey, ProjectPartyAssignSelector>;
}

export interface ISubmissionDetailsSelection {
  existingPermit: {
    show: boolean;
  };
  requiredSection?: string;
  requiredResponsibilities?: {
    show: boolean;
    responsibilities: string[];
    custom: {
      type: string;
      messages: Record<string, string>;
    };
  };
  formFields: IField[];
  designatedMembers: string[];
  visibilityCriteria?: {
    operation: 'and' | 'or';
    rules: {
      [key: string]: {
        schemes: string[];
      };
    };
  };
}

export interface ISectionStructure {
  sectionKey: string;
  subSections?: ISectionStructure[];
}

export interface ISectionContent {
  sectionKey: string;
  sectionName: string;
  accessControl?: {
    agencyName?: string;
    act?: string;
    allowedResponsibilityCodes: string[];
    isProjectCoordinator?: boolean;
    isOwner?: boolean;
    isDesignatedMember?: boolean;
    isForAllQpQpa?: boolean;
  };
  formFields?: IField[];
  fileUpload?: {
    sheetLink?: ISheetLink;
    schemeSheetLink?: {
      [scheme: string]: ISheetLink;
    };
    uploadFileTypes: IUploadFileType[];
    isSingleTag?: boolean;
  };
  declaration?: {
    declareOptions: DeclOption[];
    form: DeclContentMap;
  };
  displayOptions?: {
    showUpdateBanner?: boolean;
    disableCheckStatus?: boolean;
    excludeFromDeclaration?: boolean;
    excludeFromPlansView?: boolean;
    hideInfoPanel?: boolean;
    strictlyReadOnly?: boolean;
    sectionDoesNotRequireTechnicalClearance?: boolean;
    sectionNotRequired?: boolean;
    readOnlyView?: boolean;
    cscUraDeclarationBehavior?: boolean;
  };
  submissionNotRequired?: {
    formFields?: IField[];
    fileUpload?: {
      sheetLink?: ISheetLink;
      schemeSheetLink?: {
        [scheme: string]: ISheetLink;
      };
      uploadFileTypes: IUploadFileType[];
      isSingleTag?: boolean;
    };
  };
  computedFields?: IComputedField[];
}

export enum DeclIdentity {
  APPOINTED_QP = 'APPOINTED_QP',
  PC = 'PC',
  BUILDER = 'BUILDER',
  SITE_SUPERVISOR = 'SITE_SUPERVISOR',
  LICENSED_SPECIALIST_BUILDER = 'LICENSED_SPECIALIST_BUILDER',
  FIRE_SAFETY_ENGINEER_PEER_REVIEWER = 'FIRE_SAFETY_ENGINEER_PEER_REVIEWER',
  FIRE_SAFETY_ENGINEER = 'FIRE_SAFETY_ENGINEER',
  COMPLETION_ST_SPE_GEO = 'COMPLETION_ST_SPE_GEO',
  COMPLETION_ST_LICENSED_SPECIALIST_BUILDER = 'COMPLETION_ST_LICENSED_SPECIALIST_BUILDER',
  INCLUDE_DESIGNATED_MEMBER = 'INCLUDE_DESIGNATED_MEMBER',
}

export type DeclOption = {
  label: string;
  subLabel: string;
  identity: DeclIdentity;
};

export enum DeclSubmissionSchemeKey {
  PlanApplication = 'planApplication',
  Lodgement = 'lodgement',
  Maa = 'maa',
}

export type DeclContent = {
  title: string;
  body: DeclarationFormItem[];
};

export type DeclIdentityContentMap = {
  [key in DeclIdentity]: DeclContent;
};

export type DeclSchemeContentMap = {
  [key in DeclSubmissionSchemeKey]: DeclIdentityContentMap;
};

export type DeclContentMap = DeclIdentityContentMap | DeclSchemeContentMap;

export interface IComputedField {
  fieldName: string;
  prefix?: string;
  suffix?: string;
  padWithSpace?: boolean;
  label: string;
  numeric?: number;
  formulas: IFormula[];
  type?: string;
}

export interface IFormula {
  fieldName: string;
  operator: string;
  optional?: boolean;
}

export interface ISubmissionSchemeQuestionnaire {
  evaluationCriteria: {
    /* Section to apply this evaluation criteria to */
    sectionKey: string;
    /* Predetermined always evaluate to true */
    name: 'all-yes' | 'all-no' | 'predetermined' | 'some-no' | 'some-yes' | 'specific' | 'not-equal-to-specific';
    /* Return value if given criteria evaluates to true */
    ifTrue: { scheme: SUBMISSION_SCHEME; displayName: SUBMISSION_SCHEME_DISPLAY_NAME; tag?: string };
    /* Return value if given criteria evaluates to false */
    ifFalse?: { scheme: SUBMISSION_SCHEME; displayName: SUBMISSION_SCHEME_DISPLAY_NAME; tag?: string };
    // mapping of field and desired value
    specificMappings?: RecordStringAny[];

    visibilityCriteria?: {
      operation: 'and' | 'or';
      rules: {
        [key: string]: {
          schemes: string[];
        };
      };
    };
  }[];
  formFields: IField[];
  designatedMembers?: string[];
  hideDivider?: boolean;
}

export type SubmissionSchemeValueType = {
  type: SUBMISSION_SCHEME;
  displayName: SUBMISSION_SCHEME_DISPLAY_NAME;
};

export type SubmissionSchemeType = Record<SectionKey, SubmissionSchemeValueType>;

export type SubmissionLastUpdated = {
  userId: string;
  timeStamp: Date;
};

// TODO: use discriminated union
export type DeclarationFormItem = {
  type:
    | 'formatted'
    | 'header'
    | 'header-bold'
    | 'title'
    | 'line-break'
    | 'paragraph'
    | 'bullet-points'
    | 'disc-bullet-points'
    | 'divider'
    | 'indented-bullets-points'
    | 'indented-points'
    | 'data-table'
    | 'subheader'
    | 'subheader-small'
    | 'subheader-light'
    | 'indented-text'
    | 'ordered-list'
    | 'text';
  content?: string | string[] | ITableData | NestedListContent[] | OrderedListContent[];
  fontSize?: string;
};

export type NestedListContent = {
  text: string;
  title?: string;
  'bullet-points'?: string[];
};

export type ITableData = {
  'table-head': string[];
  'table-rows': ITableRow[];
};

export type OrderedListContent = {
  title: string;
  text: string;
  'bullet-points': string[];
  textWithLink?: string;
};

export type ITableRow = DeclarationFormItem[];

export type SheetItem = {
  type: 'header' | 'subheader' | 'line-break' | 'paragraph' | 'bullet-points' | 'sub-bullet-points' | 'note-item';
  content?: string | string[] | IBulletPoint[];
};

export type FieldType =
  | 'text'
  | 'dropdown'
  | 'address-input'
  | 'address-editor'
  | 'description-list-input'
  | 'checkbox-group'
  | 'text-area'
  | 'radio-group'
  | 'multi-select'
  | 'date-picker'
  | 'header'
  | 'divider'
  | 'sheet'
  | 'inline-notification'
  | 'project-member-select'
  | 'pre-submission-consultation-select'
  | 'combined-address-edit'
  | 'combined-address-add'
  | 'scheme-table'
  | 'info-modal'
  | 'accredited-checking-organisation'
  | 'paragraph-with-header';

export interface IFieldProps {
  groupLabel?: string;
  label?: string;
  value?: string;
  readOnly?: string;
  options?: {
    key: string;
    label: string;
  }[];
  variant?: string;
  prefix?: string;
  placeholderText?: string;
}

export interface ConditionalDisplayIdentifier {
  groupKey: string;
  optionKey: string;
  anyOption?: boolean;
}

export interface IField {
  type: FieldType;
  /** fieldName is not used by pure visual elements such as divider or header */
  fieldName?: string;
  splitReadOnly?: boolean;
  props?: RecordStringAny;
  display?: IDisplay;
  showIfNonPlanApplication?: boolean;
  skipInReadonlyView?: boolean;
  schemeTypes?: string[];
  validationRules?: { name: string; params?: unknown }[];
  customValidations?: string[];
  submission?: ISubmission | null;
  conditionalDisplay?: {
    props?: ConditionalDisplayProps;
    showIfSelected?: ConditionalDisplayIdentifier;
    disableIfChecked?: ConditionalDisplayIdentifier;
    disableIfUnchecked?: ConditionalDisplayIdentifier;
  };
  showIfEvents?: string[];
}

export interface ConditionalDisplayProps {
  /** If any of the conditions being fulfilled is sufficient */
  any?: boolean;
  no?: boolean;
}

export interface IDisplay {
  elementWidth?: 'full' | 'half' | 'minimum';
  /** Determines if the element can be placed beside another element */
  containerWidth?: 'block' | 'inline-block';
  indent?: boolean;
  columnStart?: number;
  hideInReadonly?: boolean;
}

export interface IUploadFileType {
  key: string;
  name: string;
  tagLabel: string;
  submissionType?: string;
  optional?: boolean;
  isPlan?: boolean;
  allowedExtensions?: string[];
}

export interface IBulletPoint {
  text: string;
  bulletPoints?: IBulletPoint[];
}

export interface ILink {
  label: string;
  url: string;
}

export interface ISheetLink {
  label: string;
  sheetContent: {
    title?: string;
    subTitle?: string;
    body?: string | SheetItem[];
    bulletPoints?: IBulletPoint[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content?: any;
    link?: ILink;
  };
}

export interface ISectionLock {
  lockedBy: IAccount;
  sectionKey: string;
}

export type canEditParams = {
  activeTab?: string;
  isQPAppointmentRequired?: boolean;
  isAppointedQPOrAssistant?: boolean;
  isProjectCoordinatorOrAssistant?: boolean;
  isCreatorOrAssistant?: boolean;
  isQPAppointed?: boolean;
  projectId?: string;
  submissionNotRequiredisReadyToReview?: boolean;
  hasNoQP?: boolean;
  freezeSubmission?: boolean;
} & userAndAssistantsInfo;

export type userAndAssistantsInfo = {
  currentUser?: IAccount;
  assistants?: IAssistant[];
  assistantForProjects?: {
    projectId: string;
    assisting: IAccount;
  }[];
};

export type AgencySubmittedSections = {
  [agencyCode: string]: { [submissionUuid: string]: string[] };
};

export interface ISubmissionsStageCount {
  resubmission?: { count: number; agencySubmittedSections?: AgencySubmittedSections };
  amendment?: { count: number };
}

export type Remarks = {
  header?: string;
  texts?: string[];
};

export enum MODEL_CHECK_STATE {
  DRAFT = 'DRAFT',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',

  SUBMITTED = 'SUBMITTED',
  CLEARANCE = 'CLEARANCE',
  NOT_APPROVED = 'NOT_APPROVED',
}

export enum QC_STATE {
  NOT_INITIATED = 'NOT_INITIATED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED', // QC check failure received from MCM
  ERROR = 'ERROR', // force error to get user to re-trigger QC
}

export interface IModelCheckFormData {
  'project-info'?: RecordStringAny;
  modelCheckData: RecordStringAny;
  sectionStatus: Record<string, SUBMISSION_FORM_SECTION_STATE>;
  lastUpdatedBy: ILastUpdated;
}

export interface ILastUpdated {
  [sectionKey: string]: {
    userId: string;
    timeStamp: Date;
  };
}

export interface IModelCheck {
  uuid: string;
  refNumber?: string;
  deletedAt?: Date | null;
  updatedAt: Date | null;
  state: MODEL_CHECK_STATE;
  createdBy: Omit<IAccount, 'projectMemberships'> & {
    projectMemberships: (IProjectMember & { projectId?: string })[];
  };
  project?: IProject;
  formData?: IModelCheckFormData | null;
}

export const AOPO_RELEASE_DATE = new Date('2025-02-21 00:00:00.485162+08');

export type AOPO_ASSIGNED_STATUS = 'OUTSTANDING' | 'COMPLETED';

export interface ISubmissionAopoStatus {
  uuid: string;
  name: string;
  surname: string;
  emailAddress: string;
  agency: string;
  sectionKey: string;
  sectionName: string;
  status: AOPO_ASSIGNED_STATUS;
  completionDate: Date;
}

export interface ISubmission {
  uuid: string;
  refNumber: string;
  code: string;
  title: string;
  stage?: string;
  planNumber?: string;
  project?: IProject;
  createdDate?: Date;
  createdBy?: IAccount;
  owner?: IAccount;
  lastUpdated?: Date;
  reviewedOn?: Date;
  clearanceDate?: Date | null;
  clearedAt?: Date | null;
  submittedDate?: Date | null;
  state: SUBMISSION_STATE;
  form?: IForm;
  formData?: IFormData;
  submissionsStageCount?: ISubmissionsStageCount;
  formStructure?: IForm;
  rawSectionStruct?: ISectionStructure[];
  sectionLocks?: ISectionLock[];
  payments?: IPayment[];
  agencyCorrespondence?: IAgencyCorrespondence[];
  agencyCorrespondenceBeingAddressed?: IAgencyCorrespondence;
  parentSubmissionId?: string;
  parentSubmission?: ISubmission;
  initialSubmissionId?: string;
  initialSubmission?: ISubmission;
  workflowConfig?: IWorkflowConfig;
  lastApprovedPlan?: ISubmission;
  deletedAt?: Date | null;
  planApps?: ISubmission[];
  projectInfoPulledFrom?: ISubmission;

  /**
   * Whether the current user is allowed to edit the currently active form section.
   */
  canEdit: (_params?: canEditParams) => boolean;
  canAmend: (
    _params?: {
      isProjectCoordinatorOrAssistant?: boolean;
      projectId?: string;
      applicationFor?: ISubmission;
    } & userAndAssistantsInfo,
  ) => boolean;
  canReapply: (
    _params?: {
      projectId?: string;
    } & userAndAssistantsInfo,
  ) => boolean;
  canResubmit: (
    _params?: {
      isQpQpaForSubmission?: boolean;
      isProjectCoordinatorOrAssistant?: boolean;
      projectId?: string;
    } & userAndAssistantsInfo,
  ) => boolean;
  canViewExtendWDBtn: (
    _params?: {
      projectId?: string;
    } & userAndAssistantsInfo,
  ) => boolean;
  canProceedFromDraft: (
    _params: {
      isReadyToProceedFromDraft?: boolean;
      projectId?: string;
      isProjectCoordinatorOrAssistant?: boolean;
    } & userAndAssistantsInfo,
  ) => boolean;
  /**
   * Whether this type of submission and context will involve a declaration phase.
   */
  hasDeclarationState: () => boolean;
  /**
   * Whether the current user can revert the form from declaration state to draft state.
   */
  canEditBackFromDeclaration: (
    _params: {
      projectId?: string;
      isProjectCoordinatorOrAssistant?: boolean;
    } & userAndAssistantsInfo,
  ) => boolean;
  /**
   * Determines initial form data on page load, fetching data from other submissions where required.
   */
  getInitialValues: (_params: {
    activeTab: string;
    submissions?: ISubmission[];
    isQPAppointed?: boolean;
  }) => RecordStringAny;

  canDeleteSubmission: (
    _params?: {
      isProjectCoordinatorOrAssistant?: boolean;
      projectId?: string;
    } & userAndAssistantsInfo,
  ) => boolean;

  /** Checks if the Withdraw Submission input button should be enabled */
  canTryWithdrawSubmission: () => boolean;

  canSubmitDocument: () => boolean | undefined;

  /**
   *
   * @returns {CORRESPONDENCE_TYPE_CODE[]} Returns the types of responses relevant to this type of submission.
   */
  getResponseTypes: () => CORRESPONDENCE_TYPE_CODE[];

  // if Clearance needs to be displayed as Acknowledgement, return true
  shouldDisplayClearanceAsAcknowledge(): boolean;

  /**
   * Check if submission is required to force display `Processing` as `Submitted`.
   * @returns `true` if required to force display `Processing` as `Submitted`. If not required to, then return `false`.
   * @note Cannot directly apply to ISubmission object.
   * @example
   * import { processSubmission } from 'Submissions/processSubmission.ts';
   * const exampleFn = (submission: ISubmission) => {
   *   const submissionClass = processSubmission(submission);
   *   const shouldDisplaySubmitted = submissionClass.shouldDisplayProcessingAsSubmitted(); // true || false
   *   // other codes...
   * }
   */
  shouldDisplayProcessingAsSubmitted(): boolean;

  /**
   * Check if user should be able to `View submission activity`.
   * @example
   * import { processSubmission } from 'Submissions/processSubmission.ts';
   * const exampleFn = (submission: ISubmission) => {
   *   const submissionClass = processSubmission(submission);
   *   const shouldHaveViewSubmissionActivity = submissionClass.shouldHaveViewSubmissionActivity(); // true || false
   *   // other codes...
   */
  showViewSubmissionActivity(): boolean;

  handleDesignatedMembers: (_params: { data: Record<string, string> }) => Record<string, string>;

  // InfoPanel REMARKS
  getRemarks?: () => Remarks | undefined;

  // Info panel details
  getInfoPanelDetails?: () => InfoPanelDetails[];

  // unknown can't be used here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCustomizedSection?: React.FC<any>;

  /** Returns the agency codes this submission is relevant to. */
  getAgencies(): string[];

  // enable Edit sections and schemes button under Action Panel
  displayEditSectionsSchemesBtn?: () => boolean;

  displayEditAdditionalDetailsBtn: () => boolean;

  // enable Edit related structural plans for st permit submission
  displayManageStructuralPlansAndPermitsBtn?: () => boolean;
  displayManageStructuralPlansAndPermitsBanner?: () => boolean;

  supportLodgement: () => boolean;
  requiresQualityCheck: (sectionKey: string) => boolean;
}

export enum WORKFLOW {
  TYPE_CODE = 'TYPE_CODE',
  PROCEED_FROM_DRAFT_CONTROL_RULES = 'PROCEED_FROM_DRAFT_CONTROL_RULES',
  CREATION_CONTROL_RULES = 'CREATION_CONTROL_RULES',
  USER_CREATION_ACCESS_RULES = 'USER_CREATION_ACCESS_RULES',
  ROUTING_CONFIG = 'ROUTING_CONFIG',
  PULL_PROJECT_INFO_ON_CLEARANCE = 'PULL_PROJECT_INFO_ON_CLEARANCE',
  RESPONSE_TYPES = 'RESPONSE_TYPES',
  EDIT_VIEW_INFO_PANEL_DETAILS = 'EDIT_VIEW_INFO_PANEL_DETAILS',
}

export interface IWorkflowConfig {
  name: string;
  version: number;
  workflowJson: {
    [WORKFLOW.RESPONSE_TYPES]?: CORRESPONDENCE_TYPE_CODE[];
    [WORKFLOW.EDIT_VIEW_INFO_PANEL_DETAILS]?: InfoPanelDetails[];
  };
}

export interface IAgencyCorrespondence {
  uuid: string;
  bccMembers: string;
  ccMembers: string;
  comments: string;
  correspondenceTypeCode: string;
  correspondenceDate: Date;
  subsections?: string[];
  expiryDate?: Date;
  extended: boolean;
  toMembers: string;
  attachments?: IAgencyCorrespondenceAttachment[];
  submission?: ISubmission;
  createdAt: Date;
}

export interface IAgencyCorrespondenceAttachment {
  uuid: string;
  fileInfo: {
    key: string;
    name: string;
    size: string;
  };
  agencyNames: string[];
}

export interface IAgency {
  code: string;
  name: string;
  uen: string;
  paynowUEN?: string;
  abbreviation: string;
}

export interface IPayment {
  uuid: string;
  project?: IProject;
  purpose: string;
  dueDate?: Date;
  amount: MonetaryAmount;
  receiptNumber: string;
  submission?: ISubmission;
  siteInspection?: ISiteInspection;
  transactions: IPaymentTransaction[];
  status: PAYMENT_STATUS;
  agency: IAgency;
}

export interface IGuestPayment {
  sender: IAccount;
  uuid: string;
  linkId: string;
  emailAddress?: string;
  emailSubject?: string;
  emailMessage?: string;
  createdDate: Date;
  project: IProject;
}

export interface IPaymentTransaction {
  uuid: string;
  refNumber: string;
  payer?: {
    name?: string;
    phone?: string;
    email?: string;
    address?: string;
  };
  billedTo?: {
    name?: string;
    phone?: string;
    email?: string;
    address?: string;
  };
  createdDate: Date;
  status: PAYMENT_TRANSACTION_STATUS;
  paymentMethod: PAYMENT_METHOD;
  project?: IProject;
  bankTxnNumber?: string;
  paidDate?: Date;
  payments?: IPayment[];
}

export interface INotification {
  uuid: string;
  createdDate: Date;
  type: NOTIFICATION_TYPE;
  state: NOTIFICATION_STATE;
  metadata: unknown;
}

export interface IToast {
  variant?: 'informational' | 'success' | 'error' | 'warning' | 'neutral';
  message: string;
  icon?: React.ReactNode;
  action?: {
    label: string;
    onClick: () => void;
  };
  duration?: number;
}

export interface IBroadcastMessage {
  uuid: string;
  message: string;
  action?: {
    label: string | React.ReactNode;
    onClick: () => void;
  };
  startTime?: Date;
  endTime?: Date;
  type: BROADCAST_MESSAGE_TYPE;
  category: BROADCAST_MESSAGE_CATEGORY;
  isDismissed?: boolean;
}

export interface IAssistant {
  uuid: string;
  account?: IAccount;
  canEditAll: boolean;
  emailAddress?: string;
}

export interface IRole {
  uuid: string;
  key: ROLE;
  name?: string;
  professionalNumberType: PROFESSIONAL_NUMBER_TYPE | null;
  responsibilities: IResponsibility[];
  requiresAppointment: boolean;
  meta?: Record<string, unknown>;
}

export enum ASSIGN_RESPONSIBILITY_CONDITION {
  IS_PC = 'IS_PC',
  IS_NOT_PC = 'IS_NOT_PC',
}

export interface IResponsibility {
  uuid: string;
  agencyCode: string;
  keys: string;
  order: string;
  meta?: Record<string, unknown>;
  // add assignCondition here
  assignCondition?: ASSIGN_RESPONSIBILITY_CONDITION;
  role?: IRole;
}

export interface ISubmissionEvent {
  uuid: string;
  type: SUBMISSION_EVENT;
  date: Date;
  params?: unknown;
  submission: ISubmission;
}

export interface IAllAppointedQPs {
  act: string;
  subSection: string;
  appointedQPs: IProjectMemberOrInvitation[];
  parentSection: string;
  submissionNotRequired?: boolean;
}

export interface IItems {
  tabKey: string;
  label: string;
  subsections?: {
    tabKey: string;
    label: string;
    description?: {
      text: string;
      color: 'success';
    };
  }[];
  isChecked?: boolean;
}

export interface IAppointmentRequests {
  appointed: boolean;
  appointer: IProjectMember;
  createdAt: Date;
  roles: {
    agency: IAgency;
    role: IMemberRole;
    uuid: string;
    deletedAt?: Date | string;
  }[];
  responsibilities?: RecordStringAny[];
  dateOfAppointment: Date;
  uuid: string;
  userCanAppoint?: boolean;
  deletedAt?: Date | string;
}

export interface IAppointments {
  initiatedDate: Date | string;
  appointer: IProjectMember;
  appointees: Partial<IProjectMember & { memberRelatedAppointment: boolean; memberDeletedAt: string }>[];
  id: string;
  appointed: boolean;
  dateOfAppointment?: Date | string;
  deletedAt?: Date | string;
  userCanAppoint?: boolean;
  emptyAppointmentRequest?: boolean;
}

export enum SUBMISSION_STAGE {
  NEW_SUBMISSION = 'new-submission',
  RESUBMISSION = 'resubmission',
  AMENDMENT = 'amendment',
  REAPPLICATION = 'reapplication',
}

//Possible value of gateway is "DSP", "3.5GW" or ""
export enum GATEWAY {
  DIRECT_SUBMISSION_PROCESS = 'direct-submission-process',
  DEMOLITION = 'demolition', //Demolition is not a actual gateway, will be converted to "" when sending to CP
  THREE_POINT_FIVE = 'three-point-five',
  TEMPORARY_BUILDING_STRUCTURE = 'temporary-building-structure', //Temporary building/structure is not a actual gateway, will be converted to "" when sending to CP
  PENDING = 'pending', //pending is not a actual gateway, will be converted to "" when sending to CP
}

export const gatewayLabelMap = new Map([
  [GATEWAY.DIRECT_SUBMISSION_PROCESS, 'Direct Submission Process'],
  [GATEWAY.THREE_POINT_FIVE, 'Gateway submission process'],
  [GATEWAY.DEMOLITION, ''],
  [GATEWAY.TEMPORARY_BUILDING_STRUCTURE, ''],
  [GATEWAY.PENDING, ''],
]);

export interface IFee {
  agencyCode: string;
  feeItems: {
    messages?: IPaymentMessage[];
  }[];
  amount: number;
}

export interface APIError {
  errorCode: string;
  message: string;
}

export interface IPaymentMessage {
  message?: string;
  /** @deprecated use `updateMessage` instead for accuracy */
  resubmissionMessage?: string;
  updateMessage?: string;
  tooltipInfo?: string[];
}

export const AGENCY_NAME: Record<string, string> = {
  '01': 'Building & Construction Authority (BCA)',
  '02': 'Land Transport Authority (LTA)',
  '03': 'National Environment Agency (NEA)',
  '04': 'National Parks Board (NParks)',
  '05': 'Public Utilities Board (PUB)',
  '06': 'Singapore Civil Defence Force (SCDF)',
  '07': 'Urban Redevelopment Authority (URA)',
  '08': 'JTC Corporation (JTC)',
  '09': 'Housing Development Board (HDB)',
};

export const AGENCY_SCHEME: Record<string, string> = {
  '01': 'building-design',
  '02': 'layout-plans',
  '03': 'environmental-health',
  '04': 'external-greenery',
  '05': 'sewerage',
  '06': 'fire-safety',
  '07': 'planning-parameters',
};

export const AGENCY_ABBREVIATION: Record<string, string> = {
  '01': 'BCA',
  '02': 'LTA',
  '03': 'NEA',
  '04': 'NParks',
  '05': 'PUB',
  '06': 'SCDF',
  '07': 'URA',
  '08': 'JTC',
  '09': 'HDB',
};

export interface IMemberRolesAndResponsibilities {
  uuid: string;
  emailAddress: string;
  maskedId: string;
  name: string;
  declaredResponsibilities: IDeclaredResponsibility[];
  selectedRoles: ISelectedRole[];
  extra?: Record<string, unknown>;
}

// could be better to use section names to determine order
// but using agency for now since section names are unstable and might change
export const FORM_SECTION_ORDER = ['URA', 'NParks', 'BCA', 'LTA', 'PUB', 'NEA'];

export enum CORRESPONDENCE_TYPE_CODE {
  WRITTEN_DIRECTION = 'written-direction',
  APPROVAL = 'approval',
  DISAPPROVAL = 'disapproval',
  ACKNOWLEDGEMENT = 'acknowledgement',
  CLEARANCE = 'clearance',
  RESPONSE_ISSUED = 'response-issued',

  OTHERS = 'others',
}

export enum EXTEND_WRITTEN_DIRECTION_PRE_CHECK {
  NON_SUBMISSION_COORDINATOR_OR_ASSISTANT = 'NON_SUBMISSION_COORDINATOR_OR_ASSISTANT',
  INVALID_PERIOD = 'INVALID_PERIOD',
  OK = 'OK',
}

//duplicated to backend too. please update both if needed.
export const rolePrefixMap = new Map([
  ['residentEngineerCivilAndStructure', 'RE-'],
  ['residentTechnicalOfficerCivilAndStructure', 'RTO-'],
  ['residentEngineerMechanicalAndElectrical', 'RE-ME-'],
  ['residentTechnicalOfficerMechanicalAndElectrical', 'RTO-ME-'],
  ['licensedPlumber', 'WS'],
]);

export const ILLEGAL_FILE_NAME_CHAR_REGEX = /[~"#%&*:<>?\/\\{|}]+/;

// prettier-ignore
export const VALID_FILE_UPLOAD_EXTNS = [
  'ags', 'aih', 'ain', 'atx', 'avi',
  'bcf', 'bcfzip', 'bimx', 'bmp',
  'cpg', 'csv',
  'dbf', 'dgn', 'doc', 'docx', 'dwf', 'dwg', 'dxf',
  'ent',
  'fbn', 'fbx',
  'gif',
  'ifc', 'inp0', 'inpx', 'ixs',
  'jpeg', 'jpg', 'json',
  'layx',
  'mov', 'mp4', 'mpeg', 'mxs',
  'nwd',
  'pdf', 'pla', 'pln', 'png', 'ppt', 'pptx', 'prj',
  'rvt',
  'sbn', 'sbx', 'shp', 'shx', 'sip9', 'subm',
  'tiff', 'txt',
  'xls', 'xlsx', 'xml',
  'zip',
];

export enum FILE_UPLOAD_ERROR_TYPES {
  TOO_BIG = 'TOO_BIG',
  INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
}

export enum PROCEED_FROM_DRAFT_ERROR {
  PROCESSING_PLAN_APP_AMENDMENT = 'processingPlanAppAmendment',
  NO_CLEARED_URA_SUBSECTION = 'noClearedUraSubsection',
  FEE_COMPUTATION_ERROR = 'FEE_COMPUTATION_ERROR',
  NO_CLEARED_CG_OR_DSP_SUBMISSION = 'noClearedCgOrDSPSubmission',
  NO_CLEARED_FULL_DEMOLITION_SUBMISSION = 'noClearedFullDemolitionSubmission',
  UPDATE_REQUIRED_FOR_SUBMISSION = 'updateRequiredForSubmission',
  UPDATE_3D_BIM_SUBMISSION = 'update3dBimSubmission',
  UPDATE_REQUIRED_PLANAPP_TO_LODGEMENT = 'updateRequiredPlanappToLodgement',
  UPDATE_REQUIRED_LODGEMENT_TO_PLANAPP = 'updateRequiredLodgementToPlanapp',
  UPDATE_REQUIRED_FROM_APPROVED_DG = 'updateRequiredFromApprovedDG',
  INVALID_LICENSE = 'invalidLicense',
}

export const AGENCY_CODE = {
  BCA: '01',
  LTA: '02',
  NEA: '03',
  NParks: '04',
  PUB: '05',
  SCDF: '06',
  URA: '07',
  JTC: '08',
};

export interface IAppointment {
  agency?: IAgency;
  dateOfAppointment: Date;
  role: IRole;
  appointer?: IProjectMember | null;
  declaration?: { body: DeclarationFormItem[] };
}

export interface IAppointmentStore {
  appointmentRequests?: IAppointmentRequests[];
}

export enum LBV_PROCESSING_STATUS {
  PROCESSING = 'PROCESSING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum SECTION_VALIDATION_STATUS {
  SUCCESS = 1,
  FAILED = 0,
}

export enum SECTION_VALIDATION_ERROR_TYPE {
  PARTIAL_TOP_PRE_SUB_CONSULTATION_SOME_SCOPE_USED = 'PARTIAL_TOP_PRE_SUB_CONSULTATION_SOME_SCOPE_USED',
  PARTIAL_TOP_PRE_SUB_CONSULTATION_ALL_SCOPE_USED = 'PARTIAL_TOP_PRE_SUB_CONSULTATION_ALL_SCOPE_USED',
}

export interface SECTION_VALIDATION_ERROR {
  type: SECTION_VALIDATION_ERROR_TYPE;
  payload: RecordStringAny;
}

export const JOINT_SUBMISSIONS = [
  SUBMISSION_CODES.PLAN_APPLICATION,
  SUBMISSION_CODES.PLAN_APPLICATION_3D_BIM,

  SUBMISSION_CODES.PLAN_APPLICATION_WITH_A_AND_A,
  SUBMISSION_CODES.PLAN_APPLICATION_WITH_A_AND_A_3D_BIM,

  SUBMISSION_CODES.PLAN_APPLICATION_STANDALONE,
  SUBMISSION_CODES.PLAN_APPLICATION_STANDALONE_3D_BIM,

  SUBMISSION_CODES.PLAN_APPLICATION_STANDALONE_WITH_A_AND_A,
  SUBMISSION_CODES.PLAN_APPLICATION_STANDALONE_WITH_A_AND_A_3D_BIM,

  SUBMISSION_CODES.CG_PLAN_APPLICATION,
  SUBMISSION_CODES.CG_PLAN_APPLICATION_3D_BIM,

  SUBMISSION_CODES.PG_PLAN_APPLICATION,
  SUBMISSION_CODES.PG_PLAN_APPLICATION_3D_BIM,

  SUBMISSION_CODES.DG_PLAN_APPLICATION,
  SUBMISSION_CODES.DG_PLAN_APPLICATION_3D_BIM,

  SUBMISSION_CODES.FULL_DEMOLITION_APPLICATION,
];

export const TOP_CSC_SUBMISSIONS = [
  SUBMISSION_CODES.TOP_CLEARANCE_BCA_OVERALL,
  SUBMISSION_CODES.CSC_CLEARANCE_BCA_OVERALL,

  SUBMISSION_CODES.TOP_CLEARANCE_URA_PLANNING_PARAMETERS,
  SUBMISSION_CODES.TOP_CLEARANCE_PUB_DRAINAGE,
  SUBMISSION_CODES.TOP_CLEARANCE_NEA_ENVIRONMENT_HEALTH_AND_POLLUTION_CONTROL,
  SUBMISSION_CODES.TOP_CLEARANCE_SCDF_FIRE_SAFETY,
  SUBMISSION_CODES.TOP_CLEARANCE_BCA_BUILDING_CONTROL,

  SUBMISSION_CODES.CSC_CLEARANCE_URA_PLANNING_PARAMETERS,
  SUBMISSION_CODES.CSC_CLEARANCE_NPARKS_GREENERY_PROVISION,
  SUBMISSION_CODES.CSC_CLEARANCE_LTA_ROADS,
  SUBMISSION_CODES.CSC_CLEARANCE_LTA_RAILS,
  SUBMISSION_CODES.CSC_CLEARANCE_LTA_VEHICLE_PARKING,
  SUBMISSION_CODES.CSC_CLEARANCE_PUB_DRAINAGE,
  SUBMISSION_CODES.CSC_CLEARANCE_PUB_SEWERAGE_AND_SANITARY,
  SUBMISSION_CODES.CSC_CLEARANCE_NEA_ENVIRONMENT_HEALTH_AND_POLLUTION_CONTROL,
  SUBMISSION_CODES.CSC_CLEARANCE_SCDF_FIRE_SAFETY,
  SUBMISSION_CODES.CSC_CLEARANCE_BCA_BUILDING_CONTROL,
];

export const MAX_SECONDARY_PERSONNEL = 2;

export const permitOrIndividualSubOrWaiverCodes = [
  SUBMISSION_CODES.WAIVER_NON_COMPLIANCE,
  SUBMISSION_CODES.WAIVER_BCA_BUILDING_DESIGN,
  SUBMISSION_CODES.WAIVER_BCA_STRUCTURAL_WORKS,
  SUBMISSION_CODES.WAIVER_BCA_FIXED_INSTALLATION,
  SUBMISSION_CODES.WAIVER_BCA_LIGHTNING_PROTECTION_SYSTEM,
  SUBMISSION_CODES.WAIVER_URA_PLANNING_PARAMETERS,
  SUBMISSION_CODES.WAIVER_NEA_ENVIRONMENTAL_HEALTH,
  SUBMISSION_CODES.WAIVER_NEA_POLLUTION_CONTROL,
  SUBMISSION_CODES.WAIVER_PUB_SANITARY_WORKS,
  SUBMISSION_CODES.WAIVER_PUB_SEWERAGE_WORKS,
  SUBMISSION_CODES.WAIVER_PUB_USED_WATER_PUMPING_SYSTEM,
  SUBMISSION_CODES.WAIVER_PUB_RC_TRENCH,
  SUBMISSION_CODES.WAIVER_PUB_DRAINAGE,
  SUBMISSION_CODES.WAIVER_SCDF_CD_SHELTER,
  SUBMISSION_CODES.WAIVER_SCDF_FIRE_SAFETY,
  SUBMISSION_CODES.WAIVER_LTA_VEHICULAR_PARKING,
  SUBMISSION_CODES.INDEPENDENT_SUB_NPARKS_INTERNAL_PLANTING_SCHEME,
  SUBMISSION_CODES.INDEPENDENT_SUB_NPARKS_EXTERNAL_PLANTING_SCHEME,
  SUBMISSION_CODES.INDEPENDENT_SUB_NPARKS_PARK_CONNECTOR_PLANTING_SCHEME,
  SUBMISSION_CODES.INDEPENDENT_SUB_SCDF_FIRE_PROTECTION_PLAN,
  SUBMISSION_CODES.INDEPENDENT_SUB_SCDF_ACMV,
  SUBMISSION_CODES.INDEPENDENT_SUB_PERFORMANCE_BASED_PLAN,
  SUBMISSION_CODES.INDEPENDENT_SUB_PUB_WATER_SERVICE_COMPLETION_SATISFACTION_CERTIFICATE,
  SUBMISSION_CODES.INDEPENDENT_SUB_PUB_NOTIFICATION_OF_WATER_SERVICE_INSTALLATION_WORKS,
  SUBMISSION_CODES.INDEPENDENT_SUB_PUB_WORKS_IN_PUBLIC_SEWERAGE_SYSTEM,
  SUBMISSION_CODES.INDEPENDENT_SUB_INSTRUMENTATION_MONITORING_REPORT,
  SUBMISSION_CODES.INDEPENDENT_SUB_URA_EARTHWORKS,
  SUBMISSION_CODES.INDEPENDENT_SUB_BCA_CONSTRUCTABILITY_SCORE,
  SUBMISSION_CODES.INDEPENDENT_SUB_LTA_RAILS_INSTRUMENTATION_MONITORING_REPORT,
  SUBMISSION_CODES.INDEPENDENT_SUB_LTA_APPLICATION_FOR_TAKING_OVER_STREETS,
  SUBMISSION_CODES.INDEPENDENT_SUB_LTA_TRANSPORT_IMPACT_ASSESSMENT,
  SUBMISSION_CODES.INDEPENDENT_SUB_LTA_AS_BUILT_FOR_COM,
  SUBMISSION_CODES.INDEPENDENT_SUB_LTA_NOTIFICATION_FOR_NEW_STREET_TRAFFIC,
  SUBMISSION_CODES.INDEPENDENT_SUB_URA_SUB_DIVISION,
  SUBMISSION_CODES.INDEPENDENT_SUB_BCA_CIVIL_DEFENCE_SHELTER,
  SUBMISSION_CODES.INDEPENDENT_SUB_NEA_TEMPORARY_SANITARY_FACILITIES,
  SUBMISSION_CODES.INDEPENDENT_SUB_NEA_POLLUTION_CONTROL_EQUIPMENT,
  SUBMISSION_CODES.INDEPENDENT_SUB_NEA_NOISE_ASSESSMENT,
  SUBMISSION_CODES.PERMIT_LTA_ROADS,
  SUBMISSION_CODES.PERMIT_LTA_RAILS,
];

export const planAppDemolitionOrStWorks = [
  ...JOINT_SUBMISSIONS,
  SUBMISSION_CODES.STRUCTURAL_PLAN_AND_PERMIT_APPLICATION,
  SUBMISSION_CODES.PERMIT_TO_CARRY_OUT_STRUCTURAL_WORKS,
  SUBMISSION_CODES.COMPLETION_OF_STRUCTURAL_WORKS,
];

export enum PLAN_APP_SECTION_KEY {
  PROJECT_INFO = 'project-info',
  PLANNING_PARAMETERS = 'planning-parameters',
  EXTERNAL_GREENERY = 'external-greenery',
  INTERNAL_GREENERY = 'internal-greenery',
  BUILDING_DESIGN = 'building-design',
  NON_STRUCTURAL_WORKS = 'non-structural-works',
  STRUCTURAL_WORKS = 'structural-works',
  LIGHTNING_PROTECTION_SYSTEM = 'lightning-protection-system',
  LAYOUT_PLANS = 'layout-plans',
  STREET_PLANS = 'street-plans',
  RAILS = 'rails',
  ROADS = 'roads',
  VEHICULAR_PARKING = 'vehicular-parking',
  ACTIVE_MOBILITY = 'active-mobility',
  VEHICLE_PARKING = 'vehicle-parking',
  SANITARY = 'sanitary',
  SANITARY_WORKS = 'sanitary-works',
  SEWERAGE = 'sewerage',
  SEWERAGE_WORKS = 'sewerage-works',
  SEWERAGE_AND_SANITARY = 'sewerage-and-sanitary',
  RC_TRENCH = 'rc-trench',
  USED_WATER_PUMPING_SYSTEM = 'used-water-pumping-system',
  DRAINAGE = 'drainage',
  ENVIRONMENTAL_HEALTH = 'environmental-health',
  POLLUTION_CONTROL = 'pollution-control',
  FIRE_SAFETY = 'fire-safety',
  AC_MECHANICAL_VENTILATION = 'ac-and-mechanical-ventilation-plan',
  WATER_SERVICE_COMPLETION_SATISFACTION_CERTIFICATE = 'water-service-completion-satisfaction-cerificate',
  BUILDABILITY = 'buildability',
  ENVIRONMENTAL_SUSTAINABILITY = 'environmental-sustainability',
  CIVIL_DEFENCE_SHELTER = 'civil-defence-shelter',
  CIVIL_DEFENCE_SHELTER_BUILDING = 'public-transit-civil-defence-shelter-building', //CG & DSP A&A other dev
  CIVIL_DEFENCE_SHELTER_STRUCTURAL = 'public-transit-civil-defence-shelter-structural', //CG & DSP A&A other dev
  OTHERS = 'others',
  COORDINATED_BIM = 'coordinated-bim',
  GEO_TECHNICAL_BUILDING_WORKS = 'geotechnical-building-works',
  ACCREDITED_CHECKER_CERTIFICATE = 'accredited-checker-certificate',
  SPECIALIST_ACCREDITED_CHECKER_CERTIFICATE = 'specialist-accredited-checker-certificate',
  SUBDIVISION_OF_LAND_AND_OR_BUILDING = 'subdivision-of-land-and-or-building',
  UPDATED_AS_BUILT_DRAWING_PRIOR_COM = 'updated-asbuilt-drawing-prior-com',
  BUILDING_CONTROL = 'building-control',
  BUILDING_PLAN = 'building-plan',
}

export const DSP_PLAN_APPLICATION_CODES = [
  SUBMISSION_CODES.PLAN_APPLICATION,
  SUBMISSION_CODES.PLAN_APPLICATION_3D_BIM,
  SUBMISSION_CODES.PLAN_APPLICATION_WITH_A_AND_A,
  SUBMISSION_CODES.PLAN_APPLICATION_WITH_A_AND_A_3D_BIM,
  SUBMISSION_CODES.PLAN_APPLICATION_STANDALONE,
  SUBMISSION_CODES.PLAN_APPLICATION_STANDALONE_3D_BIM,
  SUBMISSION_CODES.PLAN_APPLICATION_STANDALONE_WITH_A_AND_A,
  SUBMISSION_CODES.PLAN_APPLICATION_STANDALONE_WITH_A_AND_A_3D_BIM,
  SUBMISSION_CODES.PLAN_APPLICATION_OTHER_DEVS,
  SUBMISSION_CODES.PLAN_APPLICATION_OTHER_DEVS_3D_BIM,
];

export const CG_PLAN_APPLICATION_CODES = [
  SUBMISSION_CODES.CG_PLAN_APPLICATION,
  SUBMISSION_CODES.CG_PLAN_APPLICATION_3D_BIM,
  SUBMISSION_CODES.CG_PLAN_APPLICATION_AA,
  SUBMISSION_CODES.CG_PLAN_APPLICATION_AA_3D_BIM,
];

export const DG_PLAN_APPLICATION_CODES = [
  SUBMISSION_CODES.DG_PLAN_APPLICATION,
  SUBMISSION_CODES.DG_PLAN_APPLICATION_3D_BIM,
  SUBMISSION_CODES.DG_PLAN_APPLICATION_AA,
  SUBMISSION_CODES.DG_PLAN_APPLICATION_AA_3D_BIM,
];

export const PG_PLAN_APPLICATION_CODES = [
  SUBMISSION_CODES.PG_PLAN_APPLICATION,
  SUBMISSION_CODES.PG_PLAN_APPLICATION_3D_BIM,
  SUBMISSION_CODES.PG_PLAN_APPLICATION_AA,
  SUBMISSION_CODES.PG_PLAN_APPLICATION_AA_3D_BIM,
];

export const JOINT_SUBMISSIONS_MAIN = [
  ...DSP_PLAN_APPLICATION_CODES,
  ...CG_PLAN_APPLICATION_CODES,
  ...DG_PLAN_APPLICATION_CODES,
  SUBMISSION_CODES.FULL_DEMOLITION_APPLICATION,
];

export const PRE_SUBMISSION_CONSULTATION_CODES = [
  SUBMISSION_CODES.PRE_SUBMISSION_CONSULTATION,
  SUBMISSION_CODES.PRE_SUBMISSION_CONSULTATION_SUBMISSION_REQUIREMENTS,
  SUBMISSION_CODES.PRE_SUBMISSION_CONSULTATION_TOPCSC_REQUIREMENTS,
  SUBMISSION_CODES.PRE_SUBMISSION_CONSULTATION_PARTIAL_TOP,
  SUBMISSION_CODES.PRE_SUBMISSION_CONSULTATION_OTHERS,
  SUBMISSION_CODES.PRE_SUBMISSION_CONSULTATION_AGENCY_SPECIFIC_REQUIREMENTS,
];

export const SUBMISSION_INSTRUCTION_DETAILS_ORDER = [
  RECURSIVE_DROPDOWN_TYPE.SUBMISSION,
  RECURSIVE_DROPDOWN_TYPE.AGENCY,
  RECURSIVE_DROPDOWN_TYPE.SUBMISSION_TYPE,
  RECURSIVE_DROPDOWN_TYPE.APPLICATION_FOR,
];

export enum COMMON_VALIDATION_ERRORS {
  UNSAFE_INTEGER = 'You have reached the maximum value allowed',
}

export const SUBMISSION_WITHDRAWAL_ERROR = {
  ACTIVE_LINKED_SUBMISSION: 'ACTIVE_LINKED_SUBMISSION',
} as const;
export type SUBMISSION_WITHDRAWAL_ERROR =
  (typeof SUBMISSION_WITHDRAWAL_ERROR)[keyof typeof SUBMISSION_WITHDRAWAL_ERROR];

export enum PUBLISHED_MESSAGE_STATE {
  INIT = 'INIT',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}
