import React from 'react';

declare global {
  /* eslint-disable-next-line @typescript-eslint/no-namespace */
  namespace JSX {
    interface IntrinsicElements {
      'sgds-masthead': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export const Masthead: React.FC = () => (
  <div className="w-full bg-[#f0f0f0] sentry-block">
    <div className="mx-auto flex items-center px-5 space-x-2">
      <sgds-masthead></sgds-masthead>
    </div>
  </div>
);
