import React, { useEffect, useState } from 'react';
import { ScrollContext, useScrollToTop } from './useScrollToTop';
import { useBroadcastMessages } from './useBroadcastMessages';
import { useLocation } from 'react-router-dom';

export const PageContentWrapper: React.FC = ({ children }) => {
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const { pathname } = useLocation();

  useScrollToTop({ enabled: autoScrollEnabled });
  useBroadcastMessages();

  useEffect(() => {
    setAutoScrollEnabled(true);
  }, [setAutoScrollEnabled, pathname]);

  return (
    <ScrollContext.Provider value={{ autoScrollEnabled, setAutoScrollEnabled }}>
      <div className="bg-gray-10 w-full h-full">{children}</div>
    </ScrollContext.Provider>
  );
};
