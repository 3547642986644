import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Toaster as ReactToaster, resolveValue } from 'react-hot-toast';
import { ToastWrapper } from './ToastWrapper';

export const Toaster: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 24, right: 20 });

  const setToastBoundaries = useCallback(() => {
    if (window.innerWidth >= 768 && wrapperRef.current) {
      const { top, right } = wrapperRef.current.getBoundingClientRect();
      setPosition({
        top: window.scrollY + top + 32,
        right: right > 10 ? window.innerWidth - window.scrollX - right + 32 : 48,
      });
    }
  }, [window.innerWidth, window.scrollX, window.scrollY]);

  useEffect(() => {
    setToastBoundaries();
  }, [wrapperRef.current]);

  useEffect(() => {
    window.addEventListener('resize', setToastBoundaries);

    return () => {
      window.removeEventListener('resize', setToastBoundaries);
    };
  });

  const { top, right } = position;

  return (
    <div data-cy="toaster" className="flex flex-grow justify-end" ref={wrapperRef}>
      <ReactToaster position="top-right" gutter={12} containerStyle={{ top, right }}>
        {(t) => <ToastWrapper visible={t.visible}>{resolveValue(t.message, t)}</ToastWrapper>}
      </ReactToaster>
    </div>
  );
};
