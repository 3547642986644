import React, { useEffect, useRef, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Message } from './Message';
import classNames from 'classnames';
import { TextLink } from '../TextLink/TextLink';

type Variant = 'neutral' | 'informational' | 'error' | 'warning' | 'training';

export interface IAlertBanner {
  message: string | React.ReactNode;
  action?: {
    label: string | React.ReactNode;
    onClick: () => void;
  };
  icon?: React.ReactNode;
  variant?: Variant;
  dismissable?: boolean;
  avoidClamp?: boolean;
  closeButtonOnClick?: () => void;
}

const colorStyles = {
  informational: {
    background: 'bg-primary-60',
    icon: 'text-white',
    message: 'text-primary-10',
    action: 'text-primary-10',
  },
  error: {
    background: 'bg-error',
    icon: 'text-white',
    message: 'text-error-light',
    action: 'text-error-light',
  },
  warning: {
    background: 'bg-warning',
    icon: 'text-gray-100',
    message: 'text-gray-100',
    action: 'text-gray-100',
  },
  neutral: {
    background: 'bg-gray-80',
    icon: 'text-white',
    message: 'text-primary-10',
    action: 'text-primary-40',
  },
  training: {
    background: 'bg-[#24B6FF]',
    icon: 'text-gray-100',
    message: 'text-gray-100',
    action: 'text-gray-100',
  },
};

function isString(message: string | React.ReactNode): message is string {
  return (message as string).substr !== undefined;
}

export const AlertBanner: React.FC<IAlertBanner> = ({
  variant = 'neutral',
  icon,
  message,
  action,
  dismissable = true,
  avoidClamp = false,
  closeButtonOnClick,
}) => {
  const colors = colorStyles[variant];
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!messageRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (messageRef.current) {
        const { scrollHeight, clientHeight } = messageRef.current;
        setIsClamped(scrollHeight > clientHeight);
      }
    });

    resizeObserver.observe(messageRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className={`w-full flex items-center px-8 py-3 ${colors.background}`}>
      {icon && <div className={`flex-shrink-0 w-5 h-5 mr-3 ${colors.icon}`}>{icon}</div>}
      <div className={`flex flex-col flex-grow body-small items-start ${colors.message}`}>
        <div>
          <span
            ref={messageRef}
            className={classNames('inline max-h-[60px] overflow-hidden', {
              'line-clamp-2 sm:line-clamp-1': !(isExpanded || avoidClamp),
            })}
          >
            {isString(message) ? <Message message={message} /> : message}
          </span>
          {isClamped && !(isExpanded || avoidClamp) && (
            <TextLink
              variant="unstyled"
              className={classNames('w-max underline link-regular focus:outline-none', colors.action)}
              onClick={() => setIsExpanded(true)}
            >
              show more
            </TextLink>
          )}
        </div>
        {action && (
          <button
            className={`underline link-regular focus:outline-none pt-1 ${colors.action}`}
            onClick={action?.onClick}
          >
            {action?.label}
          </button>
        )}
      </div>
      {dismissable && (
        <button className={`focus:outline-none ml-3 ${colors.icon}`} onClick={closeButtonOnClick}>
          <XIcon className="flex-shrink-0 w-4 h-4" />
        </button>
      )}
    </div>
  );
};
