import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchBroadcastMessages } from 'apis';

export const useBroadcastMessages = (): void => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    fetchBroadcastMessages(dispatch);
  }, [pathname]);
};
