/* istanbul ignore file */

import { axiosInstance, axiosInstanceCache } from './axiosInstance';
import { Dispatch } from 'redux';
import {
  IForm,
  ISectionLock,
  ISubmissionEvent,
  ISubmission,
  SubmissionSchemeType,
  IAgencyCorrespondence,
  IPayment,
  PROCEED_FROM_DRAFT_ERROR,
  SUBMISSION_CODES,
  SUBMISSION_STATE,
  DeclContent,
  DeclIdentity,
  EXTEND_WRITTEN_DIRECTION_PRE_CHECK,
  IFormData,
  SectionKey,
  SUBMISSION_WITHDRAWAL_ERROR,
} from 'utils/constants';
import { setProject } from 'store/projects/actions';
import { setSubmission, addSubmissions, removeSubmission } from 'store/submissions/actions';
import { clearPaymentsForSubmission, setInvalidPayment, setPayments } from 'store/payments/actions';
import { processProject, RawProject } from './projects';
import { processPayment, RawPayment } from './payments';
import qs from 'qs';
import { IFileInfo, IRawSubmissionAttachment } from 'utils/interfaces/IFileInfo';
import { IRecursiveDropdownMenu, IRecursiveSelectedValue } from 'components/RecursiveDropdown/RecursiveDropdown';
import { IDeclarationHistoryItem } from 'utils/interfaces/IDeclaration';
import { queryClient } from './client';
import * as projectKeys from 'hooks/project/keys';
import * as submissionKeys from 'hooks/submission/keys';
import { processSubmissionAttachment } from '../utils/attachments';
import { IQuestionnaireData } from '../pages/Submissions/CreateSubmission/SubmissionCreationFlowV2/decisionTree';
import { api } from './fetch';

export const invalidateProjectSubmissions = (projectId?: string) => {
  if (!projectId) return;
  return queryClient.invalidateQueries({ queryKey: projectKeys.submissions(projectId) });
};

export const invalidateSubmission = (submissionId?: string) => {
  if (!submissionId) return;
  return queryClient.invalidateQueries({ queryKey: submissionKeys.item(submissionId) });
};

export const invalidateAxiosCacheByKey = async (key?: string) => {
  if (!key) return;
  await axiosInstanceCache.storage.remove(key);
};

export const getCreateSubmissionMenu = async (projectId: string): Promise<IRecursiveDropdownMenu> => {
  return axiosInstance
    .get<IRecursiveDropdownMenu>(`/submission/create-submission-menu`, { params: { projectId } })
    .then(({ data }) => data);
};

export type RawSubmission = ISubmission & {
  formData?: object;
  project?: RawProject;
  payments?: RawPayment[];
};

const processResponses = (rawAgencyCorrespondence: IAgencyCorrespondence): IAgencyCorrespondence => ({
  ...rawAgencyCorrespondence,
  correspondenceDate: new Date(rawAgencyCorrespondence.correspondenceDate),
  expiryDate: rawAgencyCorrespondence.expiryDate && new Date(rawAgencyCorrespondence.expiryDate),
});

interface IQuerySubmissionResponse {
  submissionUuid: string;
  hasOutstandingPayments: boolean;
}

export const querySubmission = async ({
  refNumber,
}: {
  refNumber: string;
}): Promise<{ id: string; hasOutstandingPayments: boolean }> => {
  const searchParams = qs.stringify({
    ref_number: refNumber,
  });

  // TODO this only returns a submission uuid (string) and hasOutstandingPayments flag, should refactor the api path as its not restful
  return axiosInstance
    .get<IQuerySubmissionResponse>(`/submission?${searchParams}`)
    .then(({ data: { submissionUuid, hasOutstandingPayments } }) => {
      return {
        id: submissionUuid,
        hasOutstandingPayments,
      };
    });
};

interface IFetchSubmissionWithOutstandingPaymentsResponse {
  submission: RawSubmission;
}

export const fetchSubmissionWithOutstandingPayments = async (dispatch: Dispatch, id: string): Promise<ISubmission> => {
  return axiosInstance
    .get<IFetchSubmissionWithOutstandingPaymentsResponse>(`/submission/${id}/outstanding`)
    .then(({ data }) => {
      const { submission } = data;
      submission.project && dispatch(setProject(processProject(submission.project)));
      dispatch(setSubmission(submission.uuid, submission));

      if (submission.payments && submission.payments.length > 0) {
        dispatch(setPayments(submission.payments?.map(processPayment)));
      } else {
        dispatch(clearPaymentsForSubmission(id));
      }

      dispatch(setInvalidPayment(false));
      return submission;
    })
    .catch((err) => {
      const paymentNotFound = err?.response?.status === 404;
      if (paymentNotFound) dispatch(setInvalidPayment(true));
      throw err;
    });
};

interface IFetchSubmissionsResponse {
  submissions: RawSubmission[];
}

export const fetchSubmissions = async (dispatch: Dispatch, projectId: string): Promise<ISubmission[]> => {
  return axiosInstanceCache
    .get<IFetchSubmissionsResponse>(`/project/${projectId}/submissions`, {
      timeout: 15 * 1000,
      id: `submissions:fetchSubmissions:${projectId}`,
    })
    .then(({ data }) => {
      const { submissions } = data;

      dispatch(addSubmissions(submissions));
      return submissions;
    });
};

export const fetchDeclarationHistoryViewableSections = async (submissionId: string): Promise<string[]> => {
  return axiosInstance
    .get<string[]>(`/submission/${submissionId}/declaration-viewable-sections`)
    .then(({ data }) => data);
};

interface IUpdateDemolitionForm {
  submission: RawSubmission;
}
export const updateDemolitionForm = async (
  dispatch: Dispatch,
  submissionId: string,
  projectId: string,
): Promise<ISubmission> => {
  return axiosInstance
    .patch<IUpdateDemolitionForm>(`/submission/${submissionId}/update-demolition-form`, { projectId })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      const { submission } = data;
      dispatch(setSubmission(submission.uuid, submission));
      return submission;
    });
};

export const updateSubmissionScheme = async (
  dispatch: Dispatch,
  submissionId: string,
  projectId: string,
  sectionsSchemeToUpdate: string[],
): Promise<ISubmission> => {
  return axiosInstance
    .patch<IUpdateDemolitionForm>(`/submission/${submissionId}/update-submission-scheme`, {
      projectId,
      sectionsSchemeToUpdate,
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      const { submission } = data;
      dispatch(setSubmission(submission.uuid, submission));
      return submission;
    });
};

interface I35GWSubmission {
  submission: RawSubmission;
}

export const update35GWForm = async (
  dispatch: Dispatch,
  submissionId: string,
  projectId: string,
): Promise<ISubmission> => {
  return axiosInstance
    .patch<I35GWSubmission>(`/submission/${submissionId}/update-35gw-form`, { projectId })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      const { submission } = data;
      dispatch(setSubmission(submission.uuid, submission));
      return submission;
    });
};

export const check3dBimDifference = async (
  submissionId: string,
): Promise<{
  is3dBimDifferent: boolean;
  type?: string;
}> => {
  return axiosInstance
    .get<{
      is3dBimDifferent: boolean;
    }>(`/submission/${submissionId}/3dbim-difference`)
    .then(({ data }) => data);
};

export const getSchemeDifference = async (
  submissionId: string,
): Promise<{
  isSchemeDifferent: boolean;
  type?: string;
}> => {
  return axiosInstance
    .get<{
      isSchemeDifferent: boolean;
      type?: string;
    }>(`/submission/${submissionId}/scheme-difference`)
    .then(({ data }) => data);
};

export const updateFormCheck = async (
  submissionId: string,
): Promise<{
  shouldUpdateForm: boolean;
}> => {
  return axiosInstance
    .get<{
      shouldUpdateForm: boolean;
    }>(`/submission/${submissionId}/update-form-check`)
    .then(({ data }) => data);
};

export const assignQPS = async (
  submissionId: string,
  projectId: string,
  qps: Record<string, string>,
): Promise<string[]> => {
  return axiosInstanceCache
    .patch<string[]>(
      `/submission/${submissionId}/assign-qps`,
      { projectId, qps },
      {
        cache: {
          update: {
            'submissions:*': 'delete',
          },
        },
      },
    )
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      return data;
    });
};

export const fetchSectionDeclarationHistory = async (
  submissionId: string,
  sectionKey: string,
): Promise<IDeclarationHistoryItem[]> => {
  return axiosInstance
    .get<IDeclarationHistoryItem[]>(`/submission/${submissionId}/declaration-history`, { params: { sectionKey } })
    .then(({ data }) => data);
};

interface IFetchSubmission {
  submission: RawSubmission;
}

export const fetchSubmission = async (dispatch: Dispatch, submissionId: string): Promise<ISubmission> => {
  return axiosInstanceCache
    .get<IFetchSubmission>(`/submission/${submissionId}`, {
      id: `submissions:fetchSubmission:${submissionId}`,
    })
    .then(({ data }) => {
      const { submission } = data;

      dispatch(setSubmission(submission.uuid, submission));
      return submission;
    });
};

export const updateSubmission = async (
  dispatch: Dispatch,
  params: { projectId?: string; submissionId?: string },
): Promise<ISubmission> => {
  return axiosInstanceCache
    .patch<IFetchSubmission>(`/submission/update-submission`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(params.submissionId);
      const { submission } = data;
      dispatch(setSubmission(submission.uuid, submission));
      return submission;
    });
};

interface ILockSubmissionSection {
  sectionLocks: ISectionLock[];
}

export const lockSubmissionSection = async (
  submissionId: string,
  params: { projectId: string; sectionKey: string },
) => {
  const res = await axiosInstance.patch<ILockSubmissionSection>(`/submission/${submissionId}/lock-section`, params, {
    headers: { 'cx-skip-refresh': 'true' },
  });
  if (!res) return;

  await invalidateSubmission(submissionId);

  return res.data.sectionLocks;
};

export const checkCanCreateSubmission_R2 = async (params: {
  projectId: string;
  code: IRecursiveSelectedValue[];
  referencedSubmissionId?: string;
}): Promise<{ canCreate: boolean; errorCode: string }> => {
  return axiosInstance
    .post<{
      canCreate: boolean;
      errorCode: string;
      referencedSubmissionId?: string;
    }>('/submission/check-able-to-create/v2', params)
    .then(({ data: { canCreate, errorCode } }) => {
      return { canCreate, errorCode };
    });
};

export const createSubmission_R2 = async (
  dispatch: Dispatch,
  params: {
    projectId: string;
    code: IRecursiveSelectedValue[];
    title: string;
    submissionScheme?: SubmissionSchemeType;
    formValue?: string[];
    parentSubmission?: string;
    designatedMembers?: Record<string, string>;
    referencedSubmissionId?: string;
    questionnaireValues?: Record<string, unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sectionsRequiredForApproval?: Record<SectionKey, any>;
  },
): Promise<ISubmission> => {
  return axiosInstanceCache
    .post<IFetchSubmissionWithOutstandingPaymentsResponse>('/submission/v2', params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateProjectSubmissions(params.projectId);
      const { submission } = data;

      dispatch(setSubmission(submission.uuid, submission));
      return submission;
    });
};

export const createSubmission = async (
  dispatch: Dispatch,
  params: {
    projectId: string;
    code: string;
    title: string;
    submissionScheme?: SubmissionSchemeType;
    formValue?: string[];
    parentSubmission?: string;
    designatedMembers?: Record<string, string>;
  },
): Promise<ISubmission> => {
  return axiosInstanceCache
    .post<IFetchSubmissionWithOutstandingPaymentsResponse>('/submission', params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateProjectSubmissions(params.projectId);
      const { submission } = data;

      dispatch(setSubmission(submission.uuid, submission));
      return submission;
    });
};

export const createResubmission = async (
  dispatch: Dispatch,
  submissionId: string,
  bodyData: {
    projectId: string;
    code: SUBMISSION_CODES[];
  },
  params?: {
    withdrawnSubmissionId?: string;
  },
): Promise<ISubmission> => {
  return axiosInstanceCache
    .post<IFetchSubmissionWithOutstandingPaymentsResponse>(`/submission/${submissionId}/resub`, bodyData, {
      params,
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateProjectSubmissions(bodyData.projectId);
      const { submission } = data;
      dispatch(setSubmission(submission.uuid, submission));
      return submission;
    });
};

export const createAmendment = async (
  submissionId: string,
  params: {
    projectId: string;
    code: SUBMISSION_CODES[];
  },
): Promise<ISubmission> => {
  return axiosInstanceCache
    .post<IFetchSubmission>(`/submission/${submissionId}/amendment`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data: { submission } }) => {
      await invalidateProjectSubmissions(params.projectId);
      return submission;
    });
};

export const createReapplication = async (
  submissionId: string,
  params: { projectId?: string },
): Promise<ISubmission> => {
  return axiosInstanceCache
    .post<IFetchSubmission>(`/submission/${submissionId}/reapplication`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data: { submission } }) => {
      await invalidateProjectSubmissions(params.projectId);
      return submission;
    });
};

export const getSubmissionAttachmentUploadPath = async (
  submissionId: string,
  params: { projectId: string; fileName: string; size: number },
): Promise<{ url: string; fields: Record<string, string>; key: string }> => {
  return axiosInstance
    .post<{
      url: string;
      fields: Record<string, string>;
      key: string;
    }>(`/submission/${submissionId}/get-upload-attachment-path`, params)
    .then(({ data }) => {
      return data;
    });
};

export const getSubmissionAttachmentDownloadPath = async ({
  fileKey,
  submissionId,
}: {
  fileKey: string;
  submissionId: string;
}): Promise<string> => {
  return axiosInstance
    .get<{ url: string }>(`/submission/${submissionId}/get-download-attachment-path/v2/${encodeURIComponent(fileKey)}`)
    .then(({ data: { url } }) => {
      return url;
    });
};

export const saveAsDraft = async (
  dispatch: Dispatch,
  submissionId: string,
  params: {
    projectId: string;
    sectionKey: string;
    sectionValues: RecordStringAny;
    sectionValuesNonQP: RecordStringAny;
  },
): Promise<void> => {
  return axiosInstanceCache
    .patch(`/submission/${submissionId}/save-as-draft`, params, {
      timeout: 15 * 1000,
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      const { submission } = data;
      dispatch(setSubmission(submission.uuid, submission));
    })
    .catch((err) => {
      throw err;
    });
};

export const enableEditing = async (
  dispatch: Dispatch,
  submissionId: string,
  params: {
    projectId: string;
    sectionKeys: string[];
    submissionNotRequired: boolean;
  },
): Promise<void> => {
  return axiosInstanceCache
    .patch(`/submission/${submissionId}/enable-editing`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      const { submission } = data;
      dispatch(setSubmission(submission.uuid, submission));
    })
    .catch((error) => {
      return error;
    });
};

export const preSubmitForReviewCheck = async (
  submissionId: string,
  params: {
    projectId: string;
    sectionKey: string;
    sectionValues: RecordStringAny;
  },
): Promise<{ message: string | null }> => {
  return axiosInstance.post(`/submission/${submissionId}/pre-submit-for-review-check`, params).then(({ data }) => {
    return data;
  });
};

export const canProceedFromDraft = async (
  submissionId: string,
  params: {
    projectId: string;
    sectionKey?: string;
  },
): Promise<{
  canProceed: boolean;
  errorCode?: PROCEED_FROM_DRAFT_ERROR;
}> => {
  return axiosInstance
    .post(`/submission/${submissionId}/can-proceed-from-draft`, params)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return error?.response?.data ?? error;
    });
};

export const fetchSubmissionUpdates = async (
  submissionId: string,
  params: {
    projectId: string;
  },
): Promise<{
  updateAvailable: boolean;
  isSubmissionUpdated: boolean;
}> => {
  return axiosInstance.post(`/submission/${submissionId}/check-submission-updates`, params).then(({ data }) => {
    return data;
  });
};

export const submitSubmissionForReview = async (
  dispatch: Dispatch,
  submissionId: string,
  params: {
    projectId: string;
    attachmentKeys: string[];
    sectionKey: string;
    sectionValues: RecordStringAny;
  },
): Promise<void> => {
  return axiosInstanceCache
    .post(`/submission/${submissionId}/submit-for-review`, params, {
      timeout: 15 * 1000,
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      const { submission } = data;

      dispatch(setSubmission(submission.uuid, submission));
    });
};

export const submitSubmissionForReviewNonQP = async (
  dispatch: Dispatch,
  submissionId: string,
  params: {
    projectId: string;
    attachmentKeys: string[];
    sectionKey?: string;
    sectionValues: RecordStringAny;
    subSections: string[];
  },
): Promise<void> => {
  return axiosInstanceCache
    .post(`/submission/${submissionId}/submit-for-review-non-QP`, params, {
      timeout: 15 * 1000,
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      const { submission } = data;

      dispatch(setSubmission(submission.uuid, submission));
    });
};

export const proceedFromDraft = async (
  submissionId: string,
  params: {
    projectId: string;
  },
): Promise<ISubmission> => {
  return await axiosInstanceCache
    .post(`/submission/${submissionId}/proceed-from-draft`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      const { submission } = data;

      return submission;
    });
};

export type DeclContentSection = {
  sectionKey: string;
  sectionTitle: string;
  identity: DeclIdentity;
  content: DeclContent;
};

export const getDeclareContents = async (submissionId: string, sectionKey: string) => {
  return (
    await axiosInstance.get<DeclContentSection[]>(`/submission/${submissionId}/declaration-content`, {
      params: { sectionKey },
    })
  ).data;
};

export const declareForSection = async (
  submissionId: string,
  params: {
    declContents: {
      identity: string;
      sectionKey: string;
      content: DeclContent;
    }[];
    declaredBy: {
      name: string;
      maskedId: string;
      roleKeys: string[];
      registration: string;
      firmName?: string;
    };
  },
): Promise<ISubmission> => {
  return await axiosInstanceCache
    .post(`/submission/${submissionId}/declare-for-section`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

export const revertPendingDeclarationToDraft = async (
  submissionId: string,
  params: {
    projectId: string;
    sectionsToRevertToDraftDueToNewQp?: string[];
  },
): Promise<ISubmission> => {
  return await axiosInstanceCache
    .post(`/submission/${submissionId}/revert-pending-declaration-to-draft`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      return data.submission;
    })
    .catch((err) => {
      throw err;
    });
};

export const getSubmissionAttachments = async (
  submissionId: string,
  { skipRefresh = false }: { skipRefresh?: boolean } = {},
): Promise<IFileInfo[]> => {
  const headers = skipRefresh ? { 'cx-skip-refresh': 'true' } : undefined;
  return axiosInstance
    .get<{
      attachments: IRawSubmissionAttachment[];
    }>(`/submission/${submissionId}/attachment`, { headers })
    .then(({ data: { attachments } }) => attachments.map(processSubmissionAttachment))
    .catch((err) => {
      throw err;
    });
};

export const getSubmissionAttachmentsForSection = async (
  submissionId: string,
  sectionKey: string,
  { skipRefresh = false }: { skipRefresh?: boolean } = {},
): Promise<IFileInfo[]> => {
  const headers = skipRefresh ? { 'cx-skip-refresh': 'true' } : undefined;
  return axiosInstance
    .get<{
      attachments: IRawSubmissionAttachment[];
    }>(`/submission/${submissionId}/${sectionKey}/attachment`, { headers })
    .then(({ data: { attachments } }) => attachments.map(processSubmissionAttachment))
    .catch((err) => {
      throw err;
    });
};

export const getApprovedSubmissionAttachments = async (submissionId: string): Promise<IFileInfo[]> => {
  return axiosInstance
    .get<{
      attachments: IRawSubmissionAttachment[];
    }>(`/submission/${submissionId}/attachment/approved`)
    .then(({ data: { attachments } }) => attachments.map(processSubmissionAttachment))
    .catch((err) => {
      throw err;
    });
};

export const addSubmissionAttachment = async (
  submissionId: string,
  params: { projectId: string; file: IFileInfo },
): Promise<void> => {
  return axiosInstanceCache
    .post(`/submission/${submissionId}/attachment`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      return data.submission;
    });
};

export const updateSubmissionAttachment = async (
  submissionId: string,
  params: { key: string; updates: Partial<IFileInfo> },
): Promise<IFileInfo> => {
  return axiosInstanceCache
    .patch<{ attachment: IFileInfo }>(`submission/${submissionId}/attachment`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      return data.attachment;
    });
};

export const removeSubmissionAttachment = async (
  submissionId: string,
  params: { key: string; projectId: string },
): Promise<void> => {
  return axiosInstanceCache.delete(`/submission/${submissionId}/attachment`, {
    params,
    cache: {
      update: {
        'submissions:*': 'delete',
      },
    },
  });
};

interface IGetSubmissionEvents {
  submissionEvents: (ISubmissionEvent & { submission: RawSubmission })[];
}

export const fetchSubmissionPayments = async (submissionId: string): Promise<IPayment[]> =>
  axiosInstance
    .get<{
      payments: RawPayment[];
    }>(`/submission/${submissionId}/payment`)
    .then(({ data: { payments } }) => payments.map(processPayment));

export const getSubmissionEvents = async (submissionId: string): Promise<{ events: ISubmissionEvent[] }> =>
  axiosInstanceCache
    .get<IGetSubmissionEvents>(`/submission/${submissionId}/event`, {
      id: `submissions:getSubmissionEvents:${submissionId}`,
    })
    .then(({ data }) => ({
      events: data.submissionEvents.map((event) => ({
        ...event,
        date: new Date(event.date),
      })),
    }));

interface IFetchFormResponse {
  form: IForm;
}

export const fetchSubmissionForm = async (submissionId: string): Promise<IForm> =>
  axiosInstance.get<IFetchFormResponse>(`/submission/${submissionId}/form`).then(({ data: { form } }) => form);

// TODO: CX-193 Allow fetching of form via version number as an additional argument
export const fetchForm = async (formName: string, projectId?: string | undefined): Promise<IForm> => {
  return axiosInstance
    .get<IFetchFormResponse>(`/submission/form/${formName}/${projectId}`)
    .then(({ data: { form } }) => form);
};

interface IFetchFormVersionResponse {
  version: string;
}
// Fetch form
export const fetchFormVersion = async (submissionId: string): Promise<string> => {
  return axiosInstance
    .get<IFetchFormVersionResponse>(`/submission/form-version/${submissionId}`)
    .then(({ data: { version } }) => version);
};

export const fetchResponses = async (submissionId: string): Promise<IAgencyCorrespondence[]> => {
  return axiosInstance
    .get<{
      agencyCorrespondences: IAgencyCorrespondence[];
    }>(`/submission/${submissionId}/response`)
    .then(({ data: { agencyCorrespondences } }) => agencyCorrespondences.map(processResponses));
};

export const getResponseAttachmentDownloadPath = async (
  submissionId: string,
  body: {
    agencyCorrespondenceId: string;
  },
): Promise<string> => {
  return axiosInstance
    .get<{
      url: string;
    }>(`/submission/${submissionId}/response/attachment/get-download-attachment-path/v2/${body.agencyCorrespondenceId}`)
    .then(({ data: { url } }) => {
      return url;
    });
};

export const getSubmissionsTypes = async (submission: string, agency: string): Promise<{ data: string[] }> => {
  return axiosInstance.get(`/submission/get-submission-types/${submission}/agency/${agency}`);
};

export const extendWrittenDirectionExpiryDate = async ({
  submissionId,
  writtenDirectionId,
  projectId,
  preCheck,
}: {
  submissionId: string;
  writtenDirectionId: string;
  projectId: string;
  preCheck?: boolean;
}): Promise<IAgencyCorrespondence | EXTEND_WRITTEN_DIRECTION_PRE_CHECK> => {
  const { data } = await axiosInstanceCache.patch<IAgencyCorrespondence | EXTEND_WRITTEN_DIRECTION_PRE_CHECK>(
    `/submission/${submissionId}/extend-wd-expiry-date/${writtenDirectionId}`,
    { projectId: projectId },
    {
      params: { preCheck: preCheck },
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    },
  );

  if (!preCheck) await invalidateSubmission(submissionId);

  return data;
};

export const deleteSubmission = async (dispatch: Dispatch, submissionId: string, projectId: string): Promise<void> => {
  return axiosInstanceCache
    .delete(`/submission/${submissionId}/delete-submission`, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async () => {
      await invalidateProjectSubmissions(projectId);
      await invalidateSubmission(submissionId);
      dispatch(removeSubmission(submissionId));
    });
};

export const submitDocuments = async (_dispatch: Dispatch, submissionId: string): Promise<void> => {
  return await axiosInstanceCache
    .post(`/submission/${submissionId}/submit-document`, undefined, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(submissionId);
      return data.submission;
    })
    .catch((err) => {
      throw err;
    });
};

export const getLbvModelUrl = async (modelId: string): Promise<{ modelUrl: string }> =>
  axiosInstance
    .post<{
      modelUrl: string;
    }>(`/lbv/get-model-url`, { modelId })
    .then(({ data: { modelUrl } }) => ({ modelUrl }));

export const getRelevantSTWorksSubmissions = async (
  permitId: string,
): Promise<Pick<ISubmission, 'uuid' | 'code' | 'title' | 'refNumber'>[]> =>
  axiosInstance.get(`/submission/${permitId}/relevant-st-works-of-permit`).then(({ data }) => data);

export const canTakeOverSubmission = async (params: {
  projectId: string;
  submissionId: string;
  code: string;
}): Promise<{ canTakeOver: boolean; errorCode: string }> => {
  return axiosInstance
    .post(`/submission/${params.submissionId}/can-take-over`, params)
    .then(({ data: { canTakeOver, errorCode } }) => {
      return { canTakeOver, errorCode };
    });
};

export const takeOverSubmission = async (
  dispatch: Dispatch,
  params: {
    projectId: string;
    submissionId: string;
    code: string;
  },
): Promise<ISubmission> => {
  return await axiosInstanceCache
    .patch(`/submission/${params.submissionId}/take-over`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(params.submissionId);
      const { submission } = data;
      dispatch(setSubmission(submission.uuid, submission));
      return submission;
    });
};

export const reassignMembersForSubmission = async (
  dispatch: Dispatch,
  params: {
    projectId: string;
    submissionId: string;
    code: string;
    designatedMembers: Record<string, string>;
  },
): Promise<ISubmission> => {
  return await axiosInstanceCache
    .patch(`/submission/${params.submissionId}/re-assign`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(params.submissionId);
      const { submission } = data;
      dispatch(setSubmission(submission.uuid, submission));
      return submission;
    });
};
export const getFilteredSubmissions = async (
  projectId: string,
  selectedSubmissionCodes: string[],
  submissionState: SUBMISSION_STATE[],
  customFilterKey?: string,
): Promise<ISubmission[]> => {
  return await axiosInstance
    .post(`/submission/${projectId}/get-filtered-submissions`, {
      selectedSubmissionCodes,
      submissionState,
      customFilterKey,
    })
    .then(({ data }) => data);
};
export const getFilteredPermitSubmissions = async (
  projectId: string,
  selectedSubmissionCodes: string[],
  submissionState: SUBMISSION_STATE,
): Promise<ISubmission[]> => {
  return await axiosInstance
    .post(`/submission/${projectId}/get-filtered-submissions`, { selectedSubmissionCodes, submissionState })
    .then(({ data }) => data);
};

export const getSubmissionSchemesQuestionnaire = async (params: {
  projectId?: string;
  code?: IRecursiveSelectedValue[];
  submissionId?: string;
}): Promise<IQuestionnaireData> =>
  await axiosInstance.post(`/submission/schemes-questionnaire`, params).then(({ data }) => data);

export const updateSectionsForApproval = async (params: {
  projectId: string;
  submissionId: string;
  sectionsRequiredForApproval?: IFormData['sectionsRequiredForApproval'];
  questionnaireValues: RecordStringAny;
  submissionScheme?: SubmissionSchemeType;
}): Promise<ISubmission> => {
  return await axiosInstanceCache
    .patch(`/submission/${params.submissionId}/sections-schemes`, params, {
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ data }) => {
      await invalidateSubmission(params.submissionId);

      return data;
    });
};

export const updateCreationQuestionaaire = async (
  submissionId: string,
  params: {
    projectId: string;
    creationData: IRecursiveSelectedValue[];
  },
): Promise<boolean> => {
  return axiosInstanceCache
    .patch(`/submission/${submissionId}/update-creation-questionaaire`, params, {
      timeout: 15 * 1000,
      cache: {
        update: {
          'submissions:*': 'delete',
        },
      },
    })
    .then(async ({ status }) => {
      await invalidateSubmission(submissionId);
      return status === 200;
    })
    .catch(() => {
      return false;
    });
};

// do not use react-query as no need to cache
// since should always be checked on each attempt to withdraw
export const checkCanWithdrawSubmission = ({ submissionId, projectId }: { submissionId: string; projectId: string }) =>
  api<{ canWithdraw: boolean; errorCode?: SUBMISSION_WITHDRAWAL_ERROR }>(
    `/submission/${submissionId}/check-able-to-withdraw`,
    {
      method: 'POST',
      body: {
        projectId,
      },
    },
  );
