import React, { useState, useCallback, useRef } from 'react';
import { MenuContainer } from './MenuContainer';
import { Input } from './Input';
import { SelectedTick, ReadOnlySelectedTick, UnselectedTick } from './SelectionIcons';
import { MultiSelectTag } from './MultiSelectTag';
import { DropdownOption } from './DropdownOption';
import { IOption } from './shared';
import { useWithFilter } from './useWithFilter';
import { NoMatch } from './NoMatch';
import { InputLink } from '../Input/Input';

export interface IMultiSelectDropdownInput {
  placeholderText?: string;
  tagOptions?: IOption[];
  options: IOption[];
  selectedOptions: string[];
  setSelectedOptions: (values: string[]) => void;
  label?: string;
  helperText?: string;
  valid?: boolean;
  errorMessage?: string | React.ReactNode;
  forceErrorShown?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  allowDeleteViaTag?: boolean;
  noMatchText?: string;
  link?: InputLink;
  className?: string;
  onFilter?: (filter: string) => void;
  icon?: React.ReactNode;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
}

export const MultiSelectDropdownInput: React.FC<IMultiSelectDropdownInput & React.HTMLAttributes<HTMLDivElement>> = ({
  placeholderText,
  tagOptions,
  options,
  selectedOptions,
  setSelectedOptions,
  label,
  helperText,
  valid,
  errorMessage,
  forceErrorShown,
  readOnly,
  disabled,
  allowDeleteViaTag = true,
  noMatchText = 'No Matches Found',
  link,
  className,
  onFilter,
  icon,
  onMenuClose,
  onMenuOpen,
}) => {
  const select = useCallback(
    (key: string) => {
      if (selectedOptions.includes(key)) {
        setSelectedOptions(selectedOptions.filter((value) => value !== key));
      } else {
        setSelectedOptions([...selectedOptions, key]);
      }
    },
    [selectedOptions, setSelectedOptions],
  );
  const {
    filter,
    setFilter,
    keyToFocus,
    filteredOptions,
    handleClick,
    handleSelect,
    increaseFocusedOption,
    decreaseFocusedOption,
  } = useWithFilter(options, select);
  const [isDirty, setIsDirty] = useState(false);
  const showError = (forceErrorShown || isDirty) && !valid && valid !== undefined;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOptionClick = useCallback(
    (key: string) => {
      handleClick(key);
      setIsDirty(true);
      inputRef.current?.select();
    },
    [handleClick, setIsDirty],
  );

  return (
    <div
      className={`relative text-gray-80 body-small md:body-regular hover:cursor-pointer ${className || ''}`}
      data-testid={'multi-select-dropdown-input'}
    >
      <Input
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        inputRef={inputRef}
        placeholderText={selectedOptions.length === 0 ? placeholderText : undefined}
        tags={
          selectedOptions.length > 0 ? (
            <>
              {selectedOptions.map((k) => (
                <MultiSelectTag
                  key={k}
                  showX={allowDeleteViaTag}
                  removeItem={(e: React.SyntheticEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    handleClick(k);
                  }}
                >
                  {tagOptions
                    ? tagOptions.find(({ key }) => key === k)?.tagLabel ||
                      tagOptions.find(({ key }) => key === k)?.displayName
                    : options.find(({ key }) => key === k)?.tagLabel ||
                      options.find(({ key }) => key === k)?.displayName}
                </MultiSelectTag>
              ))}
            </>
          ) : null
        }
        label={label}
        helperText={helperText}
        showError={showError}
        errorMessage={errorMessage}
        readOnly={readOnly}
        disabled={disabled}
        filter={filter}
        setFilter={setFilter}
        increaseFocusedOption={increaseFocusedOption}
        decreaseFocusedOption={decreaseFocusedOption}
        handleSelect={handleSelect}
        link={link}
        onFilter={onFilter}
        icon={icon}
        dropdownMenu={
          <MenuContainer>
            {filteredOptions.length > 0 ? (
              filteredOptions.map(({ key, displayName, disabled, readOnly }) => (
                <DropdownOption
                  selected={selectedOptions.includes(key)}
                  key={key}
                  optionKey={key}
                  handleClick={handleOptionClick}
                  disabled={disabled}
                  readOnly={readOnly}
                  focused={keyToFocus === key}
                >
                  <div className="flex space-x-3 items-center w-full">
                    {selectedOptions.includes(key) ? (
                      readOnly ? (
                        <ReadOnlySelectedTick />
                      ) : (
                        <SelectedTick />
                      )
                    ) : (
                      <UnselectedTick />
                    )}
                    <div>{displayName}</div>
                  </div>
                </DropdownOption>
              ))
            ) : (
              <NoMatch>{noMatchText}</NoMatch>
            )}
          </MenuContainer>
        }
      />
    </div>
  );
};
