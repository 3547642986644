/* istanbul ignore file */

import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { PageRouter } from './PageRouter/PageRouter';
import { AuthProvider } from './auth/AuthProvider';
import { TrainingEnvAuthProvider } from './auth/TrainingEnvAuthProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { InternalLinkContext } from 'ui';
import { queryClient } from './apis/client';

export const App: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <TrainingEnvAuthProvider>
        <AuthProvider>
          <InternalLinkContext.Provider value={{ Link }}>
            <PageRouter />
          </InternalLinkContext.Provider>
        </AuthProvider>
      </TrainingEnvAuthProvider>
    </QueryClientProvider>
  </Provider>
);
