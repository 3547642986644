import React from 'react';
import { IStepsIndicatorProps, PROGRESS, progress } from './shared';

interface IStepProps {
  index: number;
  title: string;
  progress: PROGRESS;
}

const progressColors: {
  [key in PROGRESS]: {
    circleBorderColor: string;
    circleBgColor: string;
    indexColor: string;
    titleColor: string;
    lineColor: string;
  };
} = {
  disabled: {
    circleBorderColor: 'border-gray-40',
    circleBgColor: 'text-white',
    indexColor: 'text-gray-40',
    titleColor: 'text-gray-40',
    lineColor: 'bg-gray-40',
  },
  active: {
    circleBorderColor: 'border-primary-60',
    circleBgColor: 'bg-primary-60',
    indexColor: 'text-white',
    titleColor: 'text-gray-80',
    lineColor: 'bg-gray-80',
  },
  completed: {
    circleBorderColor: 'border-primary-60',
    circleBgColor: 'text-primary-60',
    indexColor: 'text-primary-60',
    titleColor: 'text-gray-80',
    lineColor: 'bg-gray-80',
  },
};

const Step: React.FC<IStepProps> = ({ index, title, progress }) => {
  const { circleBorderColor, circleBgColor, indexColor, titleColor, lineColor } = progressColors[progress];
  return (
    <div className="flex flex-col flex-1" data-progress={progress}>
      <div className="flex items-center space-x-3">
        <div className={`rounded-full h-6 w-6 border-2 ${circleBorderColor} ${circleBgColor}`}>
          <div className={`flex justify-center items-center h-full w-full label-regular  ${indexColor}`}>
            {index + 1}
          </div>
        </div>
        <div className={`h-px grow ${lineColor}`}></div>
      </div>
      <div className={`mt-2`}>
        <div className={`label-large ${titleColor}`}>{title}</div>
      </div>
    </div>
  );
};

export const DesktopStepsIndicator: React.FC<IStepsIndicatorProps> = ({ steps, currentStep }) => {
  const currentStepIndex = currentStep - 1;
  return (
    <div className="hidden sm:block">
      <div className="flex space-x-3">
        {steps.map((step, index) => (
          <Step key={index} index={index} title={step} progress={progress(currentStepIndex, index)} />
        ))}
      </div>
    </div>
  );
};
