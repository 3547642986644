import React, { useContext } from 'react';
import { ColorContext } from './ColorContext';

export const Divider: React.FC = () => {
  const colorMode = useContext(ColorContext);
  const color = {
    light: 'border-gray-20',
    dark: 'border-gray-70',
  }[colorMode];

  return <hr className={`w-full ${color}`} />;
};
