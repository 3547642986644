import React, { useState } from 'react';
import { ChevronUpIcon } from '@heroicons/react/outline';
import { Tag } from '../Tag/Tag';

type IItemVariant = 'default' | 'bulleted';

export interface IItemListStacked {
  header: React.ReactNode;
  items: {
    label?: string;
    itemVariant?: IItemVariant;
    data: React.ReactNode;
    updated?: boolean;
  }[];
  updated?: boolean;
  openByDefault?: boolean;
}

export const ItemListStacked: React.FC<IItemListStacked> = ({ header, items, updated, openByDefault = true }) => {
  const [isOpen, setIsOpen] = useState<boolean>(openByDefault);
  return (
    <div className="flex flex-col divide-gray-20/60">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer py-4 px-5 bg-gray-100/[.02] flex justify-between items-center col-span-8"
      >
        {header && (
          <div className="text-gray-90 sub-heading">
            {header}
            {updated && (
              <div className="mt-2">
                <Tag text="Updated" />
              </div>
            )}
          </div>
        )}
        <div className={`transition-all transform ${isOpen ? 'rotate-0' : '-rotate-180'}`}>
          <ChevronUpIcon className="flex-shrink-0 w-5 h-5" />
        </div>
      </button>
      <hr className="col-span-8" />

      {isOpen &&
        items.map(({ label, data, itemVariant, updated }, i) => (
          <React.Fragment key={i}>
            <div className="flex flex-col space-y-1 py-4 px-5">
              <div className="over-line text-gray-60">{label}</div>
              <div className="body-small text-gray-90 break-words" data-sentry-mask>
                {itemVariant === 'bulleted' ? (
                  <ul className="list-disc list-inside">
                    {React.Children.map(data, (dataChild, index) => {
                      return <li key={index}>{dataChild}</li>;
                    })}
                  </ul>
                ) : (
                  data
                )}
                {updated && (
                  <div className="mt-2">
                    <Tag text="Updated" />
                  </div>
                )}
              </div>
            </div>
            {i !== items.length - 1 && <hr className="col-span-2" />}
          </React.Fragment>
        ))}
    </div>
  );
};
