import React from 'react';

export enum CHECKBOX_STATE {
  CHECKED,
  INDETERMINATE,
  UNCHECKED,
}

interface ICheckboxProps {
  checkboxState: CHECKBOX_STATE;
  handleChange: () => void;
  type: 'checkbox' | 'radio';
}

export interface ICheckboxOptions {
  label?: React.ReactNode;
  labelSpace?: number;
  errorMessage?: string;
  disabled?: boolean;
  isErrored?: boolean;
  readOnly?: boolean;
}

const getColorStyles = ({
  isErrored,
  checked,
  disabled,
  readOnly,
}: {
  isErrored: boolean;
  checked: boolean;
  disabled: boolean;
  readOnly: boolean;
}): string => {
  return isErrored
    ? 'bg-white border-error hover:bg-gray-10'
    : disabled
      ? checked
        ? 'bg-gray-40 border-gray-40'
        : 'bg-gray-10 border-gray-30'
      : readOnly
        ? checked
          ? 'bg-gray-60 border-gray-40'
          : 'bg-gray-10 border-gray-30'
        : checked
          ? 'bg-primary-60 border-primary-60 hover:bg-primary-50 hover:border-primary-50'
          : 'bg-white border-gray-30 hover:border-gray-80';
};

export const BaseCheckbox: React.FC<ICheckboxProps & ICheckboxOptions & React.HTMLAttributes<HTMLInputElement>> = ({
  label,
  labelSpace = 3,
  isErrored,
  errorMessage,
  disabled = false,
  readOnly = false,
  checkboxState,
  handleChange,
  type,
  className,
  ...otherProps
}) => {
  const checked = checkboxState !== CHECKBOX_STATE.UNCHECKED;

  return (
    <div className="flex flex-col gap-y-3">
      <label className={`flex space-x-${labelSpace} items-start`}>
        <div className="flex items-center rounded ring-offset-2 focus-within:ring-2 focus-within:ring-primary-60">
          <input
            onChange={handleChange}
            type={type}
            disabled={disabled || readOnly}
            checked={checked}
            className={`${getColorStyles({
              isErrored: isErrored || errorMessage !== undefined,
              disabled,
              checked,
              readOnly,
            })} rounded appearance-none cursor-pointer focus:outline-none relative button-regular text-white leading-0 border w-5 h-5 checked:after:absolute checked:after:transform checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:top-1/2 checked:after:left-1/2 content-checked-after ${className}`}
            tw-content-checked-after={
              checkboxState === CHECKBOX_STATE.INDETERMINATE
                ? '‒'
                : checkboxState === CHECKBOX_STATE.CHECKED
                  ? '✓'
                  : undefined
            }
            {...otherProps}
          />
        </div>
        {label && <div className={`${disabled ? 'text-gray-30' : 'text-gray-100'} label-large`}>{label}</div>}
      </label>
      {errorMessage && <div className="text-error captions">{errorMessage}</div>}
    </div>
  );
};
