/* istanbul ignore file */

import { AxiosResponse } from 'axios';
import { axiosInstance, axiosInstanceCache } from './axiosInstance';
import { Dispatch } from 'redux';
import { setProject } from 'store/projects/actions';
import {
  setMyPayments,
  setPayment,
  setPayments,
  setPaymentTransaction,
  setEmailAddresses,
  setPaymentReceipt,
  setGuestPayment,
  setInvalidPayment,
} from 'store/payments/actions';
import {
  IProject,
  IPayment,
  IPaymentTransaction,
  PAYMENT_TRANSACTION_STATUS,
  PAYMENT_STATUS,
  PAYMENT_METHOD,
  IGuestPayment,
  IAccount,
} from 'utils/constants';
import { MonetaryAmount } from 'utils/MonetaryAmount';
import { processProject, RawProject } from './projects';
import { sortBy } from 'lodash-es';

export type RawPayment = Omit<IPayment, 'amount | dueDate'> & { amount: number; dueDate: string };

export type RawGuestPayment = Omit<IGuestPayment, 'createdDate'> & {
  createdDate: string;
  sender: IAccount;
  payments?: RawPayment[];
};

interface IPaymentResponse {
  payments: RawPayment[];
}

interface IPaymentTransactionResponse {
  paymentTransactions: IPaymentTransaction[];
}

const statusMapping = {
  [PAYMENT_TRANSACTION_STATUS.PAID]: PAYMENT_STATUS.PAID,
  [PAYMENT_TRANSACTION_STATUS.PAID_EARLIER]: PAYMENT_STATUS.PAID,
  [PAYMENT_TRANSACTION_STATUS.FAILED]: PAYMENT_STATUS.FAILED,
  [PAYMENT_TRANSACTION_STATUS.PROCESSING]: PAYMENT_STATUS.PROCESSING,
  [PAYMENT_TRANSACTION_STATUS.AGENCY_TO_BILL]: PAYMENT_STATUS.PROCESSING,
};

export const processPayment = (payment: RawPayment): IPayment => {
  const transactions = sortBy(payment.transactions, ({ createdDate }) => new Date(createdDate).getTime()).reverse();
  const hasPaid = payment.transactions.some((txn) => txn.status === PAYMENT_TRANSACTION_STATUS.PAID);
  const transactionStatus = hasPaid ? PAYMENT_STATUS.PAID : transactions[0]?.status;

  return {
    ...payment,
    amount: new MonetaryAmount({ amount: payment.amount }),
    dueDate: payment.dueDate ? new Date(payment.dueDate) : undefined,
    status: statusMapping[transactionStatus] || PAYMENT_STATUS.DUE,
    transactions,
  };
};

export const processGuestPayment = (guestPayment: RawGuestPayment): IGuestPayment => {
  return {
    ...guestPayment,
    createdDate: new Date(guestPayment.createdDate),
  };
};

export const fetchMyPayments = async (dispatch: Dispatch): Promise<void> => {
  return axiosInstance
    .get<IPaymentResponse>(`/payment`)
    .then(({ data }) => {
      dispatch(setMyPayments(data.payments.map(processPayment)));
      dispatch(setPayments(data.payments.map(processPayment)));
    })
    .catch(() => {
      dispatch(setMyPayments(null));
    });
};

export const fetchMyPaymentTransactions = async (): Promise<IPaymentTransaction[]> => {
  return axiosInstance
    .get<IPaymentTransactionResponse>(`/account/payment-transactions`)
    .then(({ data }) => {
      return data.paymentTransactions.map((transaction) => {
        return {
          ...transaction,
          payments: transaction.payments?.map((payment) => {
            return {
              ...payment,
              amount: new MonetaryAmount({ amount: Number(payment.amount) }),
            };
          }),
        };
      });
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchProjectTransactions = async (projectId: string): Promise<IPaymentTransaction[]> => {
  return axiosInstance
    .get<IPaymentTransactionResponse>(`/project/${projectId}/payment-transactions`)
    .then(({ data }) => {
      return data.paymentTransactions.map((transaction) => {
        return {
          ...transaction,
          payments: transaction.payments?.map((payment) => {
            return {
              ...payment,
              amount: new MonetaryAmount({ amount: Number(payment.amount) }),
            };
          }),
        };
      });
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchProjectPayments = async (dispatch: Dispatch, projectId: string): Promise<void> => {
  return axiosInstance
    .get<IPaymentResponse>(`/payment/${projectId}`)
    .then(({ data }) => {
      dispatch(setPayments(data.payments.map(processPayment)));
      dispatch(setInvalidPayment(false));
    })
    .catch((err) => {
      const paymentNotFound = err?.response?.status === 404;
      if (paymentNotFound) dispatch(setInvalidPayment(true));
      throw err;
    });
};

interface ITransactionResponse {
  paymentTransaction: IPaymentTransaction & {
    transactionEmailsSent: {
      emailAddress: string;
    }[];
    payments: RawPayment[];
  };
}

export const fetchPaymentTransaction = async (
  dispatch: Dispatch,
  transactionId: string,
  options: { skipRefresh?: boolean } = {},
): Promise<void> => {
  const { skipRefresh } = options;
  const headers = skipRefresh ? { 'cx-skip-refresh': 'true' } : undefined;

  return axiosInstance
    .get<ITransactionResponse>(`/payment-transaction/${transactionId}`, { headers })
    .then(({ data }) => {
      const { paymentTransaction } = data;
      const { transactionEmailsSent, payments } = paymentTransaction;
      dispatch(setPaymentTransaction(transactionId, paymentTransaction));
      dispatch(
        setEmailAddresses(
          transactionEmailsSent.map(({ emailAddress }) => emailAddress),
          transactionId,
        ),
      );
      payments
        .map((payment) => processPayment(payment))
        .forEach((payment) => {
          dispatch(setPayment(payment));
        });
    })
    .catch();
};

export const sendEmail = async (transactionId: string, emailAddress: string): Promise<void> => {
  await axiosInstance.post(`/payment-transaction/${transactionId}/email`, { emailAddress }).catch();
};

export interface IContactDetails {
  address: {
    line1: string;
    line2: string;
    postal_code: string;
  };
  name: string;
  phone: string;
  email: string;
}

export interface IPaymentDetails {
  payer: IContactDetails;
  billing: IContactDetails;
  bankTxnNumber?: string;
  paidDate?: Date;
}

export const createPaymentTransaction = async ({
  projectId,
  paymentIds,
  paymentMethod,
  paymentDetails,
  guestPaymentLinkId,
}: {
  projectId?: string;
  paymentIds: string[];
  paymentMethod: PAYMENT_METHOD;
  paymentDetails: IPaymentDetails;
  guestPaymentLinkId?: string | null;
}): Promise<IPaymentTransaction> => {
  return axiosInstance
    .post<ITransactionResponse>(`/payment-transaction`, {
      projectId,
      paymentIds,
      paymentMethod,
      ...paymentDetails,
      guestPaymentLinkId,
    })
    .then(({ data }) => data.paymentTransaction)
    .catch();
};

export const cancelPaymentTransaction = (transactionId: string): Promise<AxiosResponse<ITransactionResponse>> =>
  axiosInstance.delete<ITransactionResponse>(`/payment-transaction/${transactionId}`).catch();

interface IPaymentIntent {
  secret: string;
}

interface IPaymentIntentResponse {
  intent: IPaymentIntent;
}

export const createPaymentIntent = async (transactionId: string): Promise<IPaymentIntent> => {
  return axiosInstanceCache
    .post<IPaymentIntentResponse>(
      `/payment/intent`,
      { transactionId },
      {
        cache: {
          update: {
            'submissions:*': 'delete',
          },
        },
      },
    )
    .then(({ data }) => data.intent)
    .catch();
};

interface ISendPaymentLinkResponse {
  guestPayment: RawGuestPayment;
}

export const sendPaymentLinkEmail = async (options: {
  emailAddress: string;
  subject: string;
  message: string;
  linkId: string;
}): Promise<string> => {
  return axiosInstance
    .post<ISendPaymentLinkResponse>(`/payment/delegate`, options)
    .then(({ data }) => data.guestPayment.linkId)
    .catch();
};

export const fetchPaymentReceipt = async (dispatch: Dispatch, paymentId: string): Promise<string> => {
  const promise = axiosInstance
    .get<ArrayBuffer>(`/payment/${paymentId}/receipt`, { responseType: 'arraybuffer' })
    .then(({ data }) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const blobURL = URL.createObjectURL(blob);
      dispatch(setPaymentReceipt(paymentId, blobURL));
      return blobURL;
    });

  promise.catch((err) => {
    dispatch(setPaymentReceipt(paymentId, null));
    // decode `error.response.data` of arrayBuffer type to JSON before propagating error
    const encoder = new TextDecoder('utf-8');
    err.response.data = JSON.parse(encoder.decode(err?.response?.data));
    throw err;
  });

  return promise;
};

export const getPaymentReceiptUrl = async (paymentId: string): Promise<string> => {
  return axiosInstance
    .get<ArrayBuffer>(`/payment/${paymentId}/receipt`, { responseType: 'arraybuffer' })
    .then(({ data }) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const blobURL = URL.createObjectURL(blob);
      return blobURL;
    })
    .catch((err) => {
      // decode `error.response.data` of arrayBuffer type to JSON before propagating error
      const encoder = new TextDecoder('utf-8');
      err.response.data = JSON.parse(encoder.decode(err?.response?.data));
      throw err;
    });
};

interface IGuestPaymentCreationResponse {
  guestPayment: RawGuestPayment;
}

export const createGuestPaymentLink = async (
  projectId: string,
  paymentIds: string[],
  emailAddress: string,
): Promise<string> => {
  return axiosInstance
    .post<IGuestPaymentCreationResponse>(`/guest-payment/`, { projectId, paymentIds, emailAddress })
    .then(({ data }) => data.guestPayment.linkId)
    .catch();
};

interface IGuestPaymentResponse {
  guestPayment: {
    project: RawProject;
    payments: RawPayment[];
  };
}

export const fetchGuestPayment = async (
  dispatch: Dispatch,
  guestPaymentId: string,
): Promise<{ project: IProject; payments: IPayment[] }> => {
  return axiosInstance
    .get<IGuestPaymentResponse>(`/guest-payment/${guestPaymentId}`)
    .then(({ data }) => {
      const { project: rawProject, payments: rawPayments } = data.guestPayment;

      const project = processProject(rawProject);
      const payments = rawPayments.map(processPayment);

      dispatch(setProject(project.uuid, project));
      dispatch(setPayments(payments));
      dispatch(setInvalidPayment(false));
      return { project, payments };
    })
    .catch((err) => {
      const paymentNotFound = err?.response?.status === 404;
      if (paymentNotFound) dispatch(setInvalidPayment(true));
      throw err;
    });
};

export const createGuestTransaction = async (
  guestPaymentId: string,
  {
    projectId,
    paymentIds,
    paymentMethod,
    paymentDetails,
  }: { projectId?: string; paymentIds: string[]; paymentMethod: PAYMENT_METHOD; paymentDetails: IPaymentDetails },
): Promise<IPaymentTransaction> => {
  return axiosInstance
    .post<ITransactionResponse>(`/guest-payment/${guestPaymentId}/transactions`, {
      projectId,
      paymentIds,
      paymentMethod,
      ...paymentDetails,
    })
    .then(({ data }) => data.paymentTransaction)
    .catch();
};

export const cancelGuestTransaction = (
  guestPaymentId: string,
  transactionId: string,
): Promise<AxiosResponse<ITransactionResponse>> =>
  axiosInstance.delete<ITransactionResponse>(`/guest-payment/${guestPaymentId}/transactions/${transactionId}`).catch();

export const createGuestPaymentIntent = async (
  guestPaymentId: string,
  transactionId: string,
): Promise<IPaymentIntent> => {
  return axiosInstance
    .post<IPaymentIntentResponse>(`/guest-payment/${guestPaymentId}/intent`, { transactionId })
    .then(({ data }) => data.intent)
    .catch();
};

export const fetchGuestPaymentTransaction = async (
  dispatch: Dispatch,
  guestPaymentId: string,
  projectId: string,
  transactionId: string,
  options: { skipRefresh?: boolean } = {},
): Promise<void> => {
  const { skipRefresh } = options;
  const headers = skipRefresh ? { 'cx-skip-refresh': 'true' } : undefined;

  return axiosInstance
    .get<ITransactionResponse>(`/guest-payment/${guestPaymentId}/transactions/${transactionId}`, { headers })
    .then(({ data }) => {
      const { paymentTransaction } = data;
      const { transactionEmailsSent, payments } = paymentTransaction;
      dispatch(setPaymentTransaction(transactionId, paymentTransaction));
      dispatch(
        setEmailAddresses(
          transactionEmailsSent.map(({ emailAddress }) => emailAddress),
          transactionId,
        ),
      );
      payments
        .map((payment) => processPayment(payment))
        .forEach((payment) => {
          dispatch(setPayment(projectId, payment));
        });
    })
    .catch();
};

export const sendGuestEmail = async (
  guestPaymentId: string,
  transactionId: string,
  emailAddress: string,
): Promise<void> => {
  await axiosInstance
    .post(`/guest-payment/${guestPaymentId}/transactions/${transactionId}/email`, { emailAddress })
    .catch();
};

export const fetchGuestPaymentReceipt = async (
  dispatch: Dispatch,
  guestPaymentId: string,
  paymentId: string,
): Promise<string> => {
  const promise = axiosInstance
    .get<ArrayBuffer>(`/guest-payment/${guestPaymentId}/${paymentId}/receipt`, { responseType: 'arraybuffer' })
    .then(({ data }) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const blobURL = URL.createObjectURL(blob);
      dispatch(setPaymentReceipt(paymentId, blobURL));
      return blobURL;
    });

  promise.catch((err) => {
    dispatch(setPaymentReceipt(paymentId, null));
    // decode `error.response.data` of arrayBuffer type to JSON before propagating error
    const encoder = new TextDecoder('utf-8');
    err.response.data = JSON.parse(encoder.decode(err?.response?.data));
    throw err;
  });

  return promise;
};

interface IFetchGuestPaymentsResponse {
  guestPayments: RawGuestPayment[];
}

export const fetchGuestPayments = async (dispatch: Dispatch, projectId: string): Promise<void> => {
  return axiosInstance
    .get<IFetchGuestPaymentsResponse>(`/project/${projectId}/guest-payments`)
    .then(({ data }) => {
      data.guestPayments.map((guestPayment) => {
        dispatch(
          setGuestPayment(processGuestPayment(guestPayment), guestPayment.payments?.map(({ uuid }) => uuid) || []),
        );
      });
    })
    .catch();
};

export const paidEarlier = async (paymentIds: string[], remarks: string): Promise<string> =>
  axiosInstanceCache
    .post(
      `/payment/paid-earlier`,
      { paymentIds, remarks },
      {
        cache: {
          update: {
            'submissions:*': 'delete',
          },
        },
      },
    )
    .then(({ data }) => data);
