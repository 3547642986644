import React, { useCallback } from 'react';
import { Footer as FooterComponent, Container, ExternalLinkOutline } from 'ui';
import { useTranslation } from 'react-i18next';
import { config } from 'config';
import { useNavigate } from 'react-router-dom';
import { CONTACT_HELP_PAGE } from 'utils/constants';

const {
  Row,
  StartAlignedContainer,
  EndAlignedContainer,
  ProductName,
  Link,
  LinksWrapper,
  Text,
  SecondaryText,
  Divider,
  GDSSection,
  PrimarySection,
  SecondarySection,
} = FooterComponent;

const TermsOfUseLink: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Link textSize="small" path="/terms-of-use">
      {t('navigation:footer.termsOfUse')}
    </Link>
  );
};

const CopyrightText: React.FC = () => {
  const { t } = useTranslation();

  return <SecondaryText>{t('navigation:footer.copyrightText', { year: new Date().getFullYear() })}</SecondaryText>;
};

export const PreLoginFooter: React.FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const redirect = useCallback((to: string) => {
    navigate(to);
  }, []);

  return (
    <FooterComponent redirect={redirect} colorMode="dark" {...props}>
      <PrimarySection>
        <Container>
          <div className="col-span-full lg:col-span-6 flex flex-col space-y-8">
            <div className="flex flex-col space-y-2">
              <Row>
                <StartAlignedContainer>
                  <ProductName>{t(`navigation:footer.r1title`)}</ProductName>
                </StartAlignedContainer>
              </Row>
              <Row>
                <StartAlignedContainer>
                  <Text>{t(`navigation:footer.r1description`)}</Text>
                </StartAlignedContainer>
              </Row>
            </div>
            <Row>
              <StartAlignedContainer>
                <LinksWrapper>
                  <Link path="/contact-us">
                    {<div data-cy="footer_contact-us-link">{t('navigation:footer.contactUs')}</div>}
                  </Link>
                  <Link external href={`${CONTACT_HELP_PAGE}`}>
                    {<div data-cy="footer_faq-link">{t('navigation:footer.faq')}</div>}
                  </Link>
                  <Link external href={`${CONTACT_HELP_PAGE}/requests/new`}>
                    {<div data-cy="footer_feedback-link">{t('navigation:footer.feedback')}</div>}
                  </Link>
                </LinksWrapper>
              </StartAlignedContainer>
            </Row>
          </div>
        </Container>
      </PrimarySection>
      <Divider />
      <SecondarySection>
        <Container>
          <div className="col-span-full flex flex-col space-y-4">
            <Row>
              <StartAlignedContainer>
                <LinksWrapper>
                  <Link textSize="small" path="/privacy-statement">
                    {t('navigation:footer.privacyStatement')}
                  </Link>
                  <TermsOfUseLink />
                  <Link
                    textSize="small"
                    external={true}
                    href="https://www.tech.gov.sg/report_vulnerability"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="flex items-center space-x-2">
                      <span>{t('navigation:footer.reportVulnerability')}</span>
                      <ExternalLinkOutline className="w-4 h-4" />
                    </div>
                  </Link>
                </LinksWrapper>
              </StartAlignedContainer>
            </Row>
            <Row>
              <StartAlignedContainer>
                <GDSSection />
              </StartAlignedContainer>
              <EndAlignedContainer>
                <SecondaryText>Version {config.version}</SecondaryText>
                <SecondaryText>
                  {t('navigation:footer.lastUpdatedDate', { lastUpdatedDate: config.lastUpdatedDate })}
                </SecondaryText>
                <CopyrightText />
              </EndAlignedContainer>
            </Row>
          </div>
        </Container>
      </SecondarySection>
    </FooterComponent>
  );
};
