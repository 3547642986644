import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Exclamation, Masthead } from 'ui';
import { PageContentWrapper } from 'PageRouter/PageContentWrapper';
import { PreLoginHeader } from 'components/Header/PreLoginHeader';
import { PreLoginFooter } from 'components/Footer/PreLoginFooter';

export const FallbackPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <PageContentWrapper>
      <div className="grid grid-rows-[min-content_min-content_auto_min-content] grid-cols-[auto] w-full min-h-screen max-w-screen">
        <Masthead />
        <PreLoginHeader />
        <div className="flex min-h-[480px] items-center justify-between">
          <div className="flex flew-grow justify-center items-center w-full">
            <div className="flex flex-col items-center space-y-3">
              <div className="flex flex-col items-center space-y-3">
                <span className="text-warning">
                  <Exclamation className="w-24 h-24" />
                </span>
                <div className="flex flex-col items-center space-y-1">
                  <span className="text-gray-80 text-center heading-4-bold">Something went wrong!</span>
                  <span className="text-center text-gray-60 body-regular">An unexpected error occurred</span>
                </div>
              </div>
              <div className="w-fit">
                <Button label="Back to CORENET X" variant="primary" onClick={() => navigate('/')} />
              </div>
            </div>
          </div>
        </div>
        <PreLoginFooter />
      </div>
    </PageContentWrapper>
  );
};
