import React, { useContext } from 'react';
import { LinkProps, ExternalLinkProps, InternalLinkProps, isExternalLink } from './shared';
import { RouteContext } from '../../../shared';
import classNames from 'classnames';

const baseStyles =
  'block focus:outline-none focus:ring-2 ring-primary-60 ring-inset text-left button-regular pl-5.5 pr-5 py-4.5 sm:px-4 sm:py-2 whitespace-nowrap';

export const Link: React.FC<LinkProps> = (props) =>
  isExternalLink(props) ? <ExternalLink {...props} /> : <InternalLink {...props} />;

const ExternalLink: React.FC<ExternalLinkProps> = ({ href, children, ...otherProps }) => (
  <a className={baseStyles} href={href} {...otherProps}>
    {children}
  </a>
);

const InternalLink: React.FC<InternalLinkProps> = ({ path, children, ...otherProps }) => {
  const { isSelected, redirect } = useContext(RouteContext);
  const selected = isSelected({ path, exact: true });

  return (
    <button
      className={classNames(baseStyles, 'hover:text-primary-50', {
        'text-primary-60': selected,
      })}
      onClick={(e: React.SyntheticEvent) => {
        e.preventDefault();
        redirect(path);
      }}
      {...otherProps}
    >
      {children}
    </button>
  );
};
