import React, { useState, useCallback, useContext } from 'react';
import { PrimaryLinkTab } from './PrimaryLinkTab';
import { FocusedTabContext, OpenStateContext } from './shared';
import { RouteContext, IRouteContext } from '../shared';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { DropdownMenu, DropdownMenuContainer, ToggleArrow } from '../../DropdownMenu';

export interface Options {
  id?: string;
  icon: React.ReactNode;
  label: string;
  path: string;
  exact: boolean;
}
export interface IOwnProps {
  isOpen: boolean;
  options: Options[];
  setIsOpen: (isOpen: boolean) => void;
}

export type ISideBar = IOwnProps & React.HTMLAttributes<HTMLElement>;

const SideBarComponent: React.FC<ISideBar & IRouteContext> = ({ path, isSelected, redirect, ...otherProps }) => {
  const [focusedTab, setFocusedTab] = useState<string | null>(null);
  return (
    <FocusedTabContext.Provider
      value={{
        value: focusedTab,
        updateValue: useCallback((key: string | null) => {
          setFocusedTab(key);
        }, []),
      }}
    >
      <RouteContext.Provider value={{ path, isSelected, redirect }}>
        <nav key="sidebar">
          <DesktopSideBar {...otherProps} />
          <MobileSideBar {...otherProps} />
        </nav>
      </RouteContext.Provider>
    </FocusedTabContext.Provider>
  );
};

const DesktopSideBar: React.FC<ISideBar> = ({ isOpen, setIsOpen, options = [] }) => {
  return (
    <OpenStateContext.Provider value={isOpen}>
      {options.length && (
        <div className="hidden sm:block h-full">
          <div className="w-22 h-full">
            <div className="absolute h-full">
              <div
                className={`relative ${
                  isOpen ? 'w-68' : 'w-22'
                } h-full flex flex-col items-center py-16 bg-primary-80 text-primary-20 transition-all duration-300`}
              >
                <div className="sm:mb-auto w-full">
                  <div className="flex flex-col items-stretch px-4">
                    {options.length &&
                      options.map((obj) => {
                        return (
                          <div id={obj.id} key={`desktop-${obj.path}`}>
                            <PrimaryLinkTab icon={obj.icon} label={obj.label} path={obj.path} exact={obj.exact} />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="focus:outline-none focus-visible:bg-gray-10 hover:bg-gray-10 absolute top-4 right-0 translate-x-5 rounded-full bg-white shadow p-2"
                >
                  <ChevronRightIcon
                    className={`${
                      isOpen ? 'rotate-180' : 'rotate-0'
                    } text-gray-70 w-5 h-5 m-auto transition-transform duration-300`}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </OpenStateContext.Provider>
  );
};

const MobileSideBar: React.FC<ISideBar> = ({ options = [] }) => {
  const { path } = useContext(RouteContext);
  const currentTab = options[options.findIndex((x) => x.path === path)] || options[0];

  return (
    <div className="sm:hidden h-auto w-screen">
      <header className="w-screen">
        {options.length && (
          <div className="flex items-center relative">
            <DropdownMenu
              closeOnChangeKey={path}
              position="bottom"
              renderControlNode={({ toggleMenu, isOpen: isMenuOpen }) => (
                <div
                  className={`focus:outline-none focus-visible:ring-2 ring-primary-60 ${
                    isMenuOpen ? 'bg-primary-100' : 'bg-primary-80'
                  }  w-full`}
                  onClick={toggleMenu}
                >
                  <div className="flex text-primary-60 flex items-center justify-between">
                    <div className="w-10/12">
                      <div className="group relative">
                        <a className={`flex w-full label-large sm:rounded-lg text-white`}>
                          <button className={`focus:outline-none w-46`}>
                            <div className={`sm:relative w-46 flex flex-row items-center`}>
                              <div className="text-secondary-50 stroke-current stroke-1 h-14 w-14 p-4">
                                {currentTab.icon}
                              </div>
                              <div>
                                <p
                                  className={`text-left sm:transform w-32 translate-x-0 opacity-100 visible transition duration-300`}
                                >
                                  {currentTab.label}
                                </p>
                              </div>
                            </div>
                          </button>
                        </a>
                      </div>
                    </div>
                    <ToggleArrow className="flex-none text-primary-10 mr-3" />
                  </div>
                </div>
              )}
            >
              <DropdownMenuContainer className="!mt-0 py-0 h-screen bg-primary-80">
                {options.length &&
                  options.map((obj) => {
                    return (
                      <div id={obj.id} key={`mobile-${obj.path}`}>
                        <PrimaryLinkTab icon={obj.icon} label={obj.label} path={obj.path} exact={obj.exact} />
                      </div>
                    );
                  })}
              </DropdownMenuContainer>
            </DropdownMenu>
          </div>
        )}
      </header>
    </div>
  );
};

export const SideBar = Object.assign(SideBarComponent, {
  PrimaryLinkTab,
});
